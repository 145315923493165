import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import '../../css/Admin/Blog_Admin.css'; // Tu CSS

const Noticias_Admin = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const navigate = useNavigate();
  const [fileError, setFileError] = useState('');
  
  useEffect(() => {
    axios.get('https://medico24x7.com/api/Admin/check_session_admin_otros.php', { withCredentials: true })
      .then(response => {
        if (response.data.authenticated) {
          setIsAuthenticated(true);
        } else {
          console.log('No autenticado');
          alert('Inicie sesión para acceder al área de consultas virtuales');
          navigate('/Admin');
        }
      })
      .catch(error => {
        console.error('Error al verificar la sesión:', error);
      });
  }, [navigate]);

  const [formData_Admin, setFormData_Admin] = useState({
    tituloNoticia: '',
    descripcionNoticia: '',
    imagen: ''
  });
  
  const [selectedFile, setSelectedFile] = useState(null); // Para manejar el archivo seleccionado
  const [message, setMessage] = useState('');

  // Manejar cambios en los campos del formulario
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData_Admin((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // Manejar la selección del archivo
  const handleFileChange = (e) => {
    const file = e.target.files[0]; // Solo permitir un archivo
    if (file) {
      // Verificar el tipo de archivo
      if (file.type === 'image/png') {
        setSelectedFile(file);
        setFileError(''); // Limpiar el mensaje de error
      } else {
        setFileError('Solo se permiten archivos PNG.');
        setSelectedFile(null); // Reiniciar el archivo seleccionado si hay un error
      }
    }
  };

  // Manejar el envío del formulario
  const handleSubmit = (e) => {
    e.preventDefault();

    // Crear un FormData para enviar los datos y el archivo
    const formData = new FormData();
    formData.append('tituloNoticia', formData_Admin.tituloNoticia);
    formData.append('descripcionNoticia', formData_Admin.descripcionNoticia);

    if (selectedFile) {
      formData.append('imagenNoticia', selectedFile); // Adjuntar el archivo
    }

    // Envío del formulario con FormData
    axios.post('https://medico24x7.com/api/Admin/blog_admin.php',
      formData, 
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        withCredentials: true,
      })
      .then(response => {
        console.log(response.data);
        setMessage(response.data.mensaje);
        if (response.data.mensaje === "Registro exitoso") {
          alert(response.data.mensaje);

           // Limpiar el formulario después del envío exitoso
        setFormData_Admin({
            tituloNoticia: '',
            descripcionNoticia: '',
            imagen: ''
          });
          setSelectedFile(null);
          setMessage('');
        }
      })
      .catch(error => {
        console.error("Hubo un error al enviar el formulario:", error);
        setMessage("Hubo un error en el registro.", error);
      });
  };

  //Codigo para cerrar sesión
  const cerrarSesion = useCallback( async () => {
    try {
    const response = await fetch('https://medico24x7.com/api/Admin/logout_admin.php', {
    method: 'POST',
    credentials: 'include', // Incluir cookies
    headers: {
      'Content-Type': 'application/json',
    },
    });
    
    const data = await response.json();
    
    if (data.success) {
    alert('La sesión ha expirado.');
    navigate('/Admin'); // Redirigir al login
    } else {
    alert('Error al cerrar la sesión');
    }
    } catch (error) {
    console.error('Error al cerrar la sesión:', error);
    }
    }, [navigate]);
    
    const timerRef = useRef(null);
    
      // Define resetTimer con useCallback para evitar recrearlo en cada renderizado
      const resetTimer = useCallback( async() => {
        clearTimeout(timerRef.current);
        timerRef.current = setTimeout(() => {
          cerrarSesion(); // Función para cerrar sesión (debes definirla o pasarla como dependencia si cambia)
        }, 1800000); // 30 minutos en milisegundos
      }, [cerrarSesion]);
    
      useEffect(() => {
        // Agregar eventos para detectar actividad del usuario
        window.addEventListener('mousemove', resetTimer);
        window.addEventListener('keypress', resetTimer);
        window.addEventListener('scroll', resetTimer);
    
        resetTimer(); // Establecer el temporizador al montar el componente
    
        // Limpiar eventos y temporizador al desmontar el componente
        return () => {
          clearTimeout(timerRef.current);
          window.removeEventListener('mousemove', resetTimer);
          window.removeEventListener('keypress', resetTimer);
          window.removeEventListener('scroll', resetTimer);
        };
      }, [resetTimer]); // Incluir resetTimer como dependencia

  if (!isAuthenticated) {
    return null;
  }

  return (
    <div className="noticias_admin_container">
      <main className='noticias_admin'>
        <div className="noticias-section">
          <h1>Registro Nuevo</h1>
          <form id="NoticiasForm" onSubmit={handleSubmit} encType="multipart/form-data">
            <fieldset>
              <legend>Formulario de Noticias</legend>

              <label htmlFor="tituloNoticia">Titulo</label>
              <input
                type="text"
                id="tituloNoticia"
                name="tituloNoticia"
                value={formData_Admin.tituloNoticia}
                onChange={handleChange}
                required
              />

              <label htmlFor="descripcionNoticia">Descripción</label>
              <textarea
                id="descripcionNoticia"
                name="descripcionNoticia"
                value={formData_Admin.descripcionNoticia}
                onChange={handleChange}
                required
              ></textarea>

              <label htmlFor="imagenNoticia" className="files"><strong>Sube foto de la noticia:</strong></label>
              <input 
                type="file" 
                id="imagenNoticia" 
                name="imagenNoticia"
                onChange={handleFileChange}
                required
              />
              {fileError && <p style={{ color: 'red' }}>{fileError}</p>} {/* Mostrar mensaje de error */}
            </fieldset>

            <button type="submit">Subir Noticia</button>
          </form>
          {message && <p>{message}</p>}
        </div>
      </main>
    </div>
  );
};

export default Noticias_Admin;