import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import '../css/metodo-pago.css'; //  estilos CSS 

function App({ closemetodo_pago }) {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [mostrarForm2, setMostrarForm2] = useState(false);
  const [metodoPago, setMetodoPago] = useState('');
  const [paypalClientId, setPaypalClientId] = useState('');
  const [loadingPaypal, setLoadingPaypal] = useState(false);
  const navigate = useNavigate();
  const [Value, setValue] = useState('');

  const [checkboxVisible, setCheckboxVisible] = useState(true);



  useEffect(() => {
    // Verificar si el usuario está autenticado al cargar la aplicación
    axios.get('https://medico24x7.com/api/check_session_pagos.php', { withCredentials: true })
      .then(response => {
        if (response.data.authenticated) {
          setIsAuthenticated(true);
          setValue(response.data.precio);
        } else {
          console.log('No autenticado');
          alert('Inicie sesión para acceder al área de consultas virtuales');
          navigate('/');// Ajusta la ruta según tu configuración
        }
      })
      .catch(error => {
        console.error('Error al verificar la sesión:', error);
      });
  }, [navigate]);

  useEffect(() => {
    if (metodoPago === 'paypal' && paypalClientId) {
      const script = document.createElement('script');
      script.src = `https://www.paypal.com/sdk/js?client-id=${paypalClientId}&currency=USD`;
      script.async = true;
      script.onload = () => {
        window.paypal.Buttons({
          createOrder: async (data, actions) => {
            return actions.order.create({
              purchase_units: [{
                amount: {
                  value: Value // Cambia por el valor real
                }
              }]
            });
          },
          onApprove: async (data, actions) => {
            const details = await actions.order.capture();
            alert('Pago completado por ' + details.payer.name.given_name);
            
            try {
              await actualizarSesion(); // Asegúrate de que esta función devuelva una promesa
              //navigate('/ConsultaChat');
              closemetodo_pago();
              window.location.reload(); // Ajusta la ruta según tu configuración
            } catch (error) {
              console.error('Error al actualizar la sesión:', error);
              alert('Ocurrió un error al procesar tu pago.');
            }
          },
          onError: (err) => {
            console.error('Error en el pago: ', err);
            alert('Hubo un error al procesar el pago, por favor intenta de nuevo.');
          }
        }).render('#paypal-container');
      };
      document.head.appendChild(script);
    }
  }, [metodoPago, paypalClientId, closemetodo_pago]);
  
  const actualizarSesion = () => {
    return axios.post('https://medico24x7.com/api/actualizar_sesion.php', { 
      metodo_de_pago: 'Paypal o Tarjeta' // Puedes enviar información adicional aquí si es necesario
    }, { withCredentials: true }) // Asegúrate de que las opciones están aquí
      .then(response => {
        if (response.data.status === 'success') {
          console.log(response.data.message);
        } else {
          console.error(response.data.message);
          alert('Error al actualizar la sesión. Por favor, intenta de nuevo.');
        }
      })
      .catch(error => {
        console.error('Error al actualizar la sesión:', error);
        alert('Ocurrió un error al procesar tu pago.');
      });
  };

  const handleCheckboxChange = (e) => {
    const estado = e.target.checked ? 'si' : 'no';
    setMostrarForm2(e.target.checked);
    axios.post('https://medico24x7.com/api/procesar.php', { aceptar: estado }, { withCredentials: true })
      .then(response => {
        // Puedes manejar la respuesta si es necesario
        console.log(response.data);
        setCheckboxVisible(false);
      })
      .catch(error => {
        console.error('Error:', error);
      });
  };

  const handleMetodoPagoChange = (e) => {
    const metodo = e.target.value;
    setMetodoPago(metodo);
    //console.log(metodo);
    if (metodo === 'paypal') {
      // Obtener el client_id desde el servidor
      setLoadingPaypal(true);
      axios.get('https://medico24x7.com/api/obtener_paypal_config.php', { withCredentials: true })
        .then(response => {
          setPaypalClientId(response.data.client_id);
          setLoadingPaypal(false);
        })
        .catch(error => {
          console.error('Error al cargar los datos de PayPal:', error);
          alert('Hubo un problema al configurar PayPal, por favor intenta de nuevo.');
          setLoadingPaypal(false);
        });
    }
  };

  const handleTransferenciaSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    formData.append('metodo_de_pago', 'Transferencia');

    axios.post('https://medico24x7.com/api/actualizar_sesion.php', formData, { withCredentials: true })
      .then(response => {
        if (response.data.status === 'success') {
          console.log(response.data.message);
          alert('Todo se envió correctamente');
          //navigate('/ConsultaChat');
          closemetodo_pago();
        } else {
          console.error(response.data.message);
          alert('Error al actualizar la sesión. Por favor, intenta de nuevo.');
        }
      })
      .catch(error => {
        console.error('Error al actualizar la sesión:', error);
        alert('Ocurrió un error al procesar tu pago.');
      });
  };

  const handleYappySubmit = (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    formData.append('metodo_de_pago', 'Yappy');

    axios.post('https://medico24x7.com/api/actualizar_sesion.php', formData, { withCredentials: true })
      .then(response => {
        if (response.data.status === 'success') {
          console.log(response.data.message);
          //navigate('/ConsultaChat');
          closemetodo_pago();
        } else {
          console.log(response.data.message);
          alert('Error al actualizar la sesión. Por favor, intenta de nuevo.'+ response.data.message);
        }
      })
      .catch(error => {
        console.error('Error al actualizar la sesión:', error);
        alert('Ocurrió un error al procesar tu pago.');
      });
  };

  if (!isAuthenticated) {
    return null; // O un loader
  }

  return (
    <div className='main_pagos'>
      <main className='pagos'>

        {/* Contenido de la página */}

        <div className="cont-formulario-pago">
          <h1>Área de Pagos</h1>
          <form id="Form">
            <fieldset>
              <legend>Agregar una hora adicional</legend>
              <label htmlFor="acuerdo">
              {checkboxVisible && (
                <input type="checkbox" id="acuerdo" name="acuerdo" onChange={handleCheckboxChange} />
              )}
                 Click en el checkbox si quiere agregar una hora adicional.
              </label>
            </fieldset>
          </form>

          {mostrarForm2 && (
            <form id="Form2" className="seleccionar">
              <fieldset>
                <legend>Selecciona un método de pago</legend>
                <select id="metodo-pago" value={metodoPago} onChange={handleMetodoPagoChange}>
                  <option value="">Seleccione...</option>
                  <option value="paypal">PayPal y Tarjetas Débito/Crédito</option>
                  <option value="transferencia">Transferencia Bancaria (Solo Panamá)</option>
                  <option value="yappy">Yappy (Solo Panamá)</option>
                </select>
              </fieldset>
            </form>
          )}

          {/* Formulario para PayPal */}
          {metodoPago === 'paypal' && (
            <div id="paypal-container" className="formulario_flotante">
              {loadingPaypal && <p>Cargando PayPal...</p>}
            </div>
          )}

          {/* Formulario para Transferencia Bancaria */}
          {metodoPago === 'transferencia' && (
            <form id="form-transferencia" className="formulario_flotante" onSubmit={handleTransferenciaSubmit}>
              <h3>Pago con Transferencia Bancaria</h3>
              <p>Por favor realiza la transferencia a una de estas cuentas:</p>
              <br />
              <p>Banco General: Enrique Puentes</p>
              <p>Cuenta Ahorros: 04-37-98-032556-7</p>
              <br />
              <p>Scotia Bank (Bank of Nova Scotia): Enrique Puentes Ruiz.</p>
              <p>Cuenta Ahorros: 200000122373</p>
              <br />
              <p>Por favor, no escriba nada en descripción o comentarios.</p>
              <fieldset>
                <label htmlFor="comprobante_tranferencia" className="files"><strong>Sube foto o captura del comprobante:</strong></label>
                <input type="file" id="comprobante_tranferencia" name="comprobante_tranferencia" multiple required />
              </fieldset>
              <button type="submit">Confirmar Transferencia</button>
            </form>
          )}

          {/* Formulario para Yappy */}
          {metodoPago === 'yappy' && (
            <form id="form-yappy" className="formulario_flotante" onSubmit={handleYappySubmit}>
              <h3>Pago con Yappy</h3>
              <p>Por favor realiza la transferencia al siguiente número de celular:</p>
              <br />
              <p>Número de celular: 6475-3624 (Enrique Puentes).</p>
              <br />
              <p>Por favor, no escriba nada en descripción o comentarios.</p>
              <fieldset>
                <label htmlFor="comprobante_tranferencia" className="files"><strong>Sube foto o captura del comprobante:</strong></label>
                <input type="file" id="comprobante_tranferencia" name="comprobante_tranferencia" multiple />
              </fieldset>
              <button type="submit">Confirmar Pago</button>
            </form>
          )}
          
          <form id="Form" className='salir_consulta'>
            <fieldset>
            <label htmlFor="acuerdo">
              Click en el boton "Salir de consulta" si ya no quieres continuar con la consulta.
            </label>
            <Link to="/"><button onClick={closemetodo_pago} className='btn_salir'>Salir de consulta</button></Link>
            </fieldset>
          </form>

        </div>
      </main>

    </div>
  );
}

export default App;