import React, { useEffect, useState } from "react";
import axios from "axios";
import "../../css/Admin/FloatingConsulta.css";

const FloatingRecibo = ({ token, onClose }) => {
  const [consultas, setConsultas] = useState([]);
  const [loading, setLoading] = useState(true); // Estado de carga

  useEffect(() => {
    const fetchData = async () => {
      if (token) {
        try {
          const response = await axios.get(
            "https://medico24x7.com/api/Usuarios/Nuevo_PHP/Recibo_Boton.php",
            {
              params: { token }, // Pasar el token como query parameter
              withCredentials: true, // Si necesitas cookies
            }
          );
          setConsultas(response.data); // Actualiza los datos
          setLoading(false); // Desactiva el estado de carga
        } catch (error) {
          console.error("Error al obtener datos:", error);
          setLoading(false); // Asegúrate de desactivar el estado de carga incluso en errores
        }
      }
    };

    fetchData();
  }, [token]);

  const handleDownload = async (id) => {
    // Llama al script PHP directamente para forzar la descarga
    const url = `https://medico24x7.com/api/Usuarios/descargar_recibo.php?id=${id}`;
    // Crea un enlace temporal y simula un clic
    const a = document.createElement('a');
    a.href = url;
    a.setAttribute('download', ''); // Esto asegura que el navegador entienda que es una descarga
    document.body.appendChild(a);
    a.click(); // Simula el clic en el enlace
    document.body.removeChild(a); // Elimina el enlace
  };

  const handleDescargarArchivo = (id) => {
    const url = `https://medico24x7.com/api/Admin/descargar_archivo_fiscal.php?id=${id}`;
    window.open(url, '_blank');
  };

  return (
    <div className="floating-overlay">
      <div className="floating-botton">
        <button onClick={onClose} className="floating-close-button">
          Cerrar
        </button>
      </div>
      <div className="floating-modal">
        <table className="consultas_table">
          {loading ? (
            //animación de carga de datos
            <p>Cargando datos...</p>
          ) : Array.isArray(consultas) && consultas.length > 0 ? (
            consultas.map((consulta, index) => (
              <tbody key={consulta.id}>
                <tr>
                  <td><strong>Nombre</strong></td>
                  <td>{consulta.nombre}</td>
                </tr>
                <tr>
                  <td><strong>Apellido</strong></td>
                  <td>{consulta.apellido}</td>
                </tr>
                <tr>
                  <td><strong>Cédula</strong></td>
                  <td>{consulta.cedula}</td>
                </tr>
                <tr>
                  <td><strong>Celular</strong></td>
                  <td>{consulta.celular}</td>
                </tr>
                <tr>
                  <td><strong>Monto</strong></td>
                  <td>{consulta.monto}</td>
                </tr>
                <tr>
                  <td><strong>Metodo de Pago</strong></td>
                  <td>{consulta.metodo_de_pago}</td>
                </tr>
                <tr>
                  <td><strong>Descargar Comprobante</strong></td>
                  <td>
                    {consulta.comprobante_tranferencia_encrypted ? (
                      <div className='descarga'>
                        <button onClick={() => handleDownload(consulta.id)}>
                          Descargar
                        </button>
                        <img className='img_recibo'
                          src={`data:image/png;base64,${consulta.comprobante_tranferencia_encrypted}`}
                          alt="Comprobante de Pago"
                          style={{ width: '150px', height: 'auto' }}
                        />
                      </div>
                    ) : (
                      "No disponible"
                    )}
                  </td>
                </tr>
                <tr>
                  <td><strong>Recibo Fiscal</strong></td>
                  <td>
                    {consulta.archivo_nombre ? (
                      <button onClick={() => handleDescargarArchivo(consulta.id)}>
                        Descargar Archivo
                      </button>
                    ) : (
                      "No disponible"
                    )}
                  </td>
                </tr>

              </tbody>
            ))
          ) : (
            <p>No se encontraron datos.</p>
          )}
        </table>
      </div>
    </div>

  );
};

export default FloatingRecibo;