import React, { useState, useEffect } from 'react';
import Cookies from 'js-cookie';  // Asegúrate de tener js-cookie instalado

const CookieConsent = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const cookieConsent = Cookies.get('cookieConsent');
    if (!cookieConsent) {
      // Mostrar el mensaje si no se ha aceptado aún
      setIsVisible(true);
    }
  }, []);

  const handleBannerClick = () => {
    // Aceptar las cookies y ocultar el banner
    Cookies.set('cookieConsent', true, { expires: 365 });
    setIsVisible(false);
  };

  return (
    isVisible && (
      <div style={styles.cookieBanner} onClick={handleBannerClick}>
        <p>Este sitio utiliza cookies para el funcionamiento correcto de la página web. Haz clic para aceptar.</p>
      </div>
    )
  );
};

const styles = {
  cookieBanner: {
    position: 'fixed',
    bottom: '0',
    width: '100%',
    backgroundColor: '#000',
    color: '#fff',
    padding: '10px',
    textAlign: 'center',
    zIndex: '1100',
    cursor: 'pointer',  // Agregamos un cursor pointer para indicar que es clicable
  },
};

export default CookieConsent;