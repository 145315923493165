import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import FloatingConsulta from "./ConsultasFloating";
import '../../css/Admin/Sugerencias_Admin_Nuevo.css';

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    // Verificar si el usuario está autenticado al cargar la aplicación
    axios.get('https://medico24x7.com/api/check_session_pagos.php', { withCredentials: true })
      .then(response => {
        if (response.data.authenticated) {
          setIsAuthenticated(true);
        } else {
          console.log('No autenticado');
          alert('Inicie sesión para acceder al área de consultas virtuales');
          navigate('/');// Ajusta la ruta según tu configuración
        }
      })
      .catch(error => {
        console.error('Error al verificar la sesión:', error);
      });
  }, [navigate]);

  const [usuarios, setUsuarios] = useState([]);

  // Cargar las consultas desde la base de datos cuando se monta el componente
  useEffect(() => {
    axios.get('https://medico24x7.com/api/Usuarios/Nuevo_PHP/Consulta.php', { withCredentials: true })
      .then(response => {
        if (!Array.isArray(response.data)) {
          alert("No se encontraron resultados", response.data);
          return;
        }
        setUsuarios(response.data);
      })
      .catch(error => {
        alert('Error al obtener las consultas:', error);
      });
  }, []);

//Codigo para cerrear sesion despues de 30 minutos
const cerrarSesion = useCallback( async () => async () => {
  try {
    const response = await fetch('https://medico24x7.com/api/logout.php', {
      method: 'POST',
      credentials: 'include', // Incluir cookies
      headers: {
        'Content-Type': 'application/json',
      },
    });

    const data = await response.json();

    if (data.success) {
      alert('La sesión ha expirado. Serás redirigido al login.');
      navigate('/'); // Redirigir al login
      setIsAuthenticated(false);
    } else {
      alert('Error al cerrar la sesión');
    }
  } catch (error) {
    console.error('Error al cerrar la sesión:', error);
  }
}, [navigate]);

const timerRef = useRef(null);
  
// Define resetTimer con useCallback para evitar recrearlo en cada renderizado
const resetTimer = useCallback( async() => {
  clearTimeout(timerRef.current);
  timerRef.current = setTimeout(() => {
    cerrarSesion(); // Función para cerrar sesión (debes definirla o pasarla como dependencia si cambia)
  }, 1800000); // 30 minutos en milisegundos
}, [cerrarSesion]);

useEffect(() => {
  // Agregar eventos para detectar actividad del usuario
  window.addEventListener('mousemove', resetTimer);
  window.addEventListener('keypress', resetTimer);
  window.addEventListener('scroll', resetTimer);

  resetTimer(); // Establecer el temporizador al montar el componente

  // Limpiar eventos y temporizador al desmontar el componente
  return () => {
    clearTimeout(timerRef.current);
    window.removeEventListener('mousemove', resetTimer);
    window.removeEventListener('keypress', resetTimer);
    window.removeEventListener('scroll', resetTimer);
  };
}, [resetTimer]); // Incluir resetTimer como dependencia

const [isFloatingVisible, setIsFloatingVisible] = useState(false);
const [selectedToken, setSelectedToken] = useState(null);

const handleButtonClick = (id) => {
  setSelectedToken(id); // Establece el token para el componente flotante
  setIsFloatingVisible(true); // Muestra el componente flotante
};

  if (!isAuthenticated) {
    return null; // O un loader
  }

  return (
    <div className='sugerencia_admin'>
    <main className='sugerencia_admin_main'>
      <div class="parent-sugerencia">
        <div class="div1-sugerencia">
        <ul className="ul-sugerencia-cont">
        {usuarios.map((product) => (
          <section key={product.id} className="li-consulta-cont">
            <div class="parent-sugerencia-cont">
                <div class="div1-sugerencia-cont">
                    <p className="p-element-sugerencia"><strong>Nombre:</strong> {product.nombre} {product.apellido}</p>
                    <p className="p-element-sugerencia"><strong>Cedula:</strong> {product.cedula}</p>
                </div>
                <div class="div2-sugerencia-cont">
                    <p className="p-element-sugerencia"><strong>Correo:</strong> {product.correo}</p>
                </div>
                <div class="div3-sugerencia-cont">
                    <p className="p-element-sugerencia"><strong>Fecha de Registro:</strong> {product.fecha_registro}</p>
                </div>
                <div class="div4-sugerencia-cont">
                    <button className="button-element-sugerencia" 
                    onClick={() => handleButtonClick(product.id)}>
                    <strong>Información Completa</strong>
                    </button>
                </div>
            </div>

          </section>
        ))}
      </ul>
      </div>
      </div>
      {isFloatingVisible && (
        <FloatingConsulta
          token={selectedToken}
          onClose={() => setIsFloatingVisible(false)} // Función para cerrar
        />
      )}
    </main>
    </div>
  );
}

export default App;