import React, { useState } from "react";
import "../css/SoundCloudPlayer.css"; // Importa el archivo CSS

const SoundCloudPlayer = () => {
  // Estado para controlar la visibilidad del iframe
  const [isVisible, setIsVisible] = useState(false);

  // Función para alternar la visibilidad
  const toggleIframe = () => {
    setIsVisible(!isVisible);
  };

  return (
    <div>
      <button className="btnPlayer" onClick={toggleIframe}>
        {isVisible ? "Ocultar SoundCloud" : "Mostrar SoundCloud"}
      </button>
      
      <iframe
        className="iframe"
        style={{
          opacity: isVisible ? 1 : 0, // Cambia la opacidad
          visibility: isVisible ? 'visible' : 'hidden', // Cambia la visibilidad
          transition: 'opacity 0.5s ease', // Añade transición para suavizar el cambio
        }}
        width="100%"
        height="100%"
        allow="autoplay"
        src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/1900569551%3Fsecret_token%3Ds-yV5mRhwjP3T&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true"
        title="SoundCloud Playlist"
      ></iframe>
    </div>
  );
};

export default SoundCloudPlayer;