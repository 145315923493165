import React from 'react';
import { useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';
import '../css/Footer.css';

const Footer = () => {
  const location = useLocation();
  const isConsultaVirtuales = location.pathname === '/pagos' || location.pathname === '/ConsultaChat';

  if (
    location.pathname === '/Admin' || 
    location.pathname === '/Admin/' || 
    location.pathname === '/Consultas_Admin' || 
    location.pathname === '/Citas_Admin' || 
    location.pathname === '/Recibo_Admin' || 
    location.pathname === '/Sugerencias_Admin' ||
    location.pathname === '/AdminDoctor' || 
    location.pathname === '/Consultas_Admin_Doctor' || 
    location.pathname === '/Citas_Admin_Doctor' ||
    location.pathname === '/Blog_Admin'
  ) {
    return null;
  }

  return (
    <footer className="footer">
      <div className="container_consulta_usuario">
        <small>
          © 2024 {isConsultaVirtuales ? (
            <span className="brand-name-sin-estilo" >Medico24x7</span>
          ) : (
            <Link to="/" className="brand-name">Medico24x7</Link>
          )}. Todos los derechos reservados.
        </small>
      </div>
    </footer>
  );
};

export default Footer;