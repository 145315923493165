import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import '../../css/Admin/Registro_Admin.css'; // Tu CSS

const Registro_Admin = () => {

  const navigate = useNavigate();

  const [emailError, setEmailError] = useState(false);

  // Inicializar con valores predeterminados
  const [formData_Admin, setFormData_Admin] = useState({
    nombre: '',
    apellido: '',
    correo: '',
    password: '',
    password_confirm: '',
    clave_seguridad: '',
  });

  // Manejar cambios en los campos del formulario
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData_Admin((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // Validar el correo electrónico
  const validateEmail = (e) => {
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    setEmailError(!emailPattern.test(e.target.value));
  };

  const [message, setMessage] = useState('');

  // Manejar el envío del formulario
  const handleSubmit = (e) => {
    e.preventDefault();

    // Verificar si hay errores antes de enviar
    if (emailError) {
      setMessage("Por favor, corrige los errores en el formulario.");
      return;
    }

    if (formData_Admin.password !== formData_Admin.password_confirm) {
        setMessage('Las contraseñas no coinciden');
        return;
      }

    // Envío del formulario
    axios.post('https://medico24x7.com/api/Admin/registro_admin.php', 
      {
        nombre: formData_Admin.nombre,
        apellido: formData_Admin.apellido,
        correo: formData_Admin.correo,
        password: formData_Admin.password,
        clave_seguridad: formData_Admin.clave_seguridad,
      }, {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true,
    })
    .then(response => {
      console.log(response.data); // Mostrar la respuesta del servidor
      // Aquí puedes manejar la respuesta, como mostrar un mensaje de éxito
      setMessage(response.data.mensaje);
      if(response.data.mensaje === "Registro exitoso"){
      navigate('/Login_Admin');
      }
    })
    .catch(error => {
      console.error("Hubo un error al enviar el formulario:", error);
      setMessage("Hubo un error en el registro.");
    });
  };

  return (
    <div className="registro_admin_container">
      <main className='registro_admin'>
        <div className="registro-section">
          <h1>Registro de Usuario Admin</h1>
          <form id="registrationForm" onSubmit={handleSubmit}>
            <fieldset>
              <legend>Datos Personales</legend>

              <label htmlFor="clave_seguridad">Clave De Seguridad</label>
              <input
                type="password"
                id="clave_seguridad"
                name="clave_seguridad"
                value={formData_Admin.clave_seguridad}
                onChange={handleChange}
                required
              />

              <label htmlFor="nombre">Nombre</label>
              <input
                type="text"
                id="nombre"
                name="nombre"
                value={formData_Admin.nombre}
                onChange={handleChange}
                required
              />

              <label htmlFor="apellido">Apellidos</label>
              <input
                type="text"
                id="apellido"
                name="apellido"
                value={formData_Admin.apellido}
                onChange={handleChange}
                required
              />

              <label htmlFor="correo">Email</label>
              <input
                type="email"
                id="correo"
                name="correo"
                value={formData_Admin.correo}
                onChange={(e) => { handleChange(e); validateEmail(e); }}
                required
              />
              {emailError && <p className='p_error'>Por favor, ingresa un correo válido.</p>}

              <label htmlFor="password">Contraseña</label>
              <input
                type="password"
                id="password"
                name="password"
                value={formData_Admin.password}
                onChange={handleChange}
                required
              />

              <label htmlFor="password_confirm">Confirmar Contraseña</label>
              <input
                type="password"
                id="password_confirm"
                name="password_confirm"
                value={formData_Admin.password_confirm}
                onChange={handleChange}
                required
              />
            </fieldset>

            <button type="submit">Registrarse</button>
          </form>
          {message && <p>{message}</p>}
        </div>
      </main>
    </div>
  );
};

export default Registro_Admin;
