import React, { useEffect, useState } from "react";
import axios from "axios";
import "../../css/Admin/FloatingConsulta.css";

const FloatingRecibo = ({ token, onClose }) => {
  const [consultas, setConsultas] = useState([]);
  const [loading, setLoading] = useState(true); // Estado de carga

  useEffect(() => {
    const fetchData = async () => {
      if (token) {
        try {
          const response = await axios.get(
            "https://medico24x7.com/api/Admin/Nuevo_PHP/Recibo_Admin_Boton.php",
            {
              params: { token }, // Pasar el token como query parameter
              withCredentials: true, // Si necesitas cookies
            }
          );
          setConsultas(response.data); // Actualiza los datos
          setLoading(false); // Desactiva el estado de carga
        } catch (error) {
          console.error("Error al obtener datos:", error);
          setLoading(false); // Asegúrate de desactivar el estado de carga incluso en errores
        }
      }
    };

    fetchData();
  }, [token]);

  const handleDownload = async (id) => {
    const url = `https://medico24x7.com/api/Admin/descargar_recibo.php?id=${id}`;
    const a = document.createElement('a');
    a.href = url;
    a.setAttribute('download', '');
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  const handleActualizarFila = async (consulta) => {
    const formData = new FormData();

    // Recopilar los datos necesarios
    formData.append('id', consulta.id);
    formData.append('revisado', consulta.revisado ? 1 : 0);
    if (consulta.archivo) {
      formData.append('archivo', consulta.archivo);
    }

    console.log('Valores enviados:', {
      id: consulta.id,
      revisado: consulta.revisado ? 1 : 0,
      archivo: consulta.archivo
    });

    try {
      const response = await axios.post('https://medico24x7.com/api/Admin/actualizar_pagos.php', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        withCredentials: true
      });

      if (response.status === 200) {
        alert(response.data.message);
        console.log(response.data)
      } else {
        alert("Error al actualizar la fila.");
      }
    } catch (error) {
      console.error("Error en la actualización:", error);
      alert("Error de conexión al actualizar la fila.");
    }
  };

  const handleFileChange = (e, index) => {
    const file = e.target.files[0];
    setConsultas(prevConsultas => {
      const updatedConsultas = [...prevConsultas];
      updatedConsultas[index] = { ...updatedConsultas[index], archivo: file };
      return updatedConsultas;
    });
  };


  const handleDescargarArchivo = (id) => {
    const url = `https://medico24x7.com/api/Admin/descargar_archivo_fiscal.php?id=${id}`;
    window.open(url, '_blank');
  };

  return (
    <div className="floating-overlay">
      <div className="floating-botton">
        <button onClick={onClose} className="floating-close-button">
          Cerrar
        </button>
      </div>
      <div className="floating-modal">
        <table className="consultas_table">
          {loading ? (
            //animación de carga de datos
            <p>Cargando datos...</p>
          ) : Array.isArray(consultas) && consultas.length > 0 ? (
            consultas.map((consulta, index) => (
              <tbody key={consulta.id}>
                <tr>
                  <td><strong>Revisado</strong></td>
                  <td>
                    <input
                      type="checkbox"
                      name="revisado[]"
                      value={consulta.id}
                      checked={consulta.revisado || false}
                      onChange={() => setConsultas(prev => {
                        const updated = [...prev];
                        updated[index] = {
                          ...updated[index],
                          revisado: !consulta.revisado // Invierte el estado actual de revisado
                        };
                        return updated;
                      })}
                    />
                  </td>
                </tr>
                <tr>
                  <td><strong>Nombre</strong></td>
                  <td>{consulta.nombre}</td>
                </tr>
                <tr>
                  <td><strong>Apellido</strong></td>
                  <td>{consulta.apellido}</td>
                </tr>
                <tr>
                  <td><strong>Cédula</strong></td>
                  <td>{consulta.cedula}</td>
                </tr>
                <tr>
                  <td><strong>Celular</strong></td>
                  <td>{consulta.celular}</td>
                </tr>
                <tr>
                  <td><strong>Monto</strong></td>
                  <td>{consulta.monto}</td>
                </tr>
                <tr>
                  <td><strong>Acuerdo</strong></td>
                  <td>{consulta.acuerdo}</td>
                </tr>
                <tr>
                  <td><strong>Metodo de Pago</strong></td>
                  <td>{consulta.metodo_de_pago}</td>
                </tr>
                <tr>
                  <td><strong>Token</strong></td>
                  <td>{consulta.token}</td>
                </tr>
                <tr>
                  <td><strong>Descargar Comprobante</strong></td>
                  <td>
                    {consulta.comprobante_tranferencia_encrypted ? (
                      <div className='descarga'>
                        <button onClick={() => handleDownload(consulta.id)}>
                          Descargar
                        </button><br /><br />
                        <img className='img_recibo'
                          src={`data:image/png;base64,${consulta.comprobante_tranferencia_encrypted}`}
                          alt="Comprobante de Pago"
                          style={{ width: '150px', height: 'auto', borderRadius: '5px' }}
                        />
                      </div>
                    ) : (
                      "No disponible"
                    )}
                  </td>
                </tr>

                <tr>
                  <td><strong>Subir Recibo Fiscal (PDF)</strong></td>
                  <td>
                    <input type="file" onChange={(e) => handleFileChange(e, index)} />
                  </td>
                </tr>


                <tr>
                  <td><strong>Recibo Fiscal</strong></td>
                  <td>
                    {consulta.archivo_nombre ? (
                      <button onClick={() => handleDescargarArchivo(consulta.id)}>
                        Descargar Archivo
                      </button>
                    ) : (
                      "No disponible"
                    )}
                  </td>
                </tr>

                <tr>
                  <td><strong>Actualizar Datos</strong></td>
                  <td>
                    <button onClick={() => handleActualizarFila(consulta)}>
                      Actualizar
                    </button>
                  </td>
                </tr>

              </tbody>
            ))
          ) : (
            <p>No se encontraron datos.</p>
          )}
        </table>
      </div>
    </div>

  );
};

export default FloatingRecibo;