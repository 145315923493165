import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import '../css/login.css'; //  CSS

const Login = ({ closeLogin }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [message, setMessage] = useState('');
  const navigate = useNavigate();
  

  // Referencia al contenedor del formulario
  const formRef = useRef(null);

  // useEffect para manejar clics fuera del formulario
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (formRef.current && !formRef.current.contains(event.target)) {
        closeLogin(); // Llama a la función para cerrar el login
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [closeLogin]);

  const handleLogin = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post('https://medico24x7.com/api/login.php', {
        email: email,
        password: password
      }, {
        withCredentials: true,
      });

      if (response.data.success) {
        setMessage(response.data.message);
        navigate('/consultas-virtuales');
        window.location.reload();
        closeLogin();
      } else {
        setMessage(response.data.message); 
        console.log(response.data)
      }
    } catch (error) {
      console.error("Error en la solicitud:", error);
      setMessage("Hubo un error en el login.");
    }
  };

  return (
    <div className="login__container">
      <main className="login" ref={formRef}>
        <div className='login-section'>
          <h2>Inicio de Sesión</h2>
          <form onSubmit={handleLogin}>
            <input
              type="email"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            <input
              type="password"
              placeholder="Contraseña"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
            <button type="submit">Iniciar Sesión</button>
          </form>
          {message && <p>{message}</p>}
        </div>
      </main>
    </div>
  );
};

export default Login;