import { useEffect } from 'react';

const GTranslateWidget = () => {
  useEffect(() => {
    // Configurar el widget de GTranslate
    window.gtranslateSettings = {
      default_language: 'es', // Idioma por defecto: español
      detect_browser_language: true, // Detectar el idioma del navegador
      languages: [ 'en', 'es', 'fr', 'de', 'it', 'zh-TW', 'zh-CN'], // Idiomas disponibles
      wrapper_selector: '.gtranslate_wrapper', // Selector para el contenedor
      switcher_horizontal_position: 'right', // Posición horizontal
      switcher_vertical_position: 'top', // Posición vertical
      flag_style: '3d', // Estilo de la bandera 3D
      alt_flags: {
        en: 'usa' // Bandera alternativa para el inglés
      }
    };

    // Crear y cargar el script del widget
    const script = document.createElement('script');
    script.src = 'https://cdn.gtranslate.net/widgets/latest/float.js';
    script.defer = true;
    document.body.appendChild(script);

    // Limpiar el script cuando el componente se desmonte
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return <div className="gtranslate_wrapper"></div>;
};

export default GTranslateWidget;