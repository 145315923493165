import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
//import Metodo_Pago from './metodo_pago';
import axios from 'axios';
import '../css/ConsultasVirtuales.css'; // Estilos CSS 

const ConsultasVirtuales = () => {

const [isAuthenticated, setIsAuthenticated] = useState(false);

 //Inicio de sesión
 /*const [showmetodo_pago, setShowmetodo_pago] = useState(false);
 const openmetodo_pago = () => setShowmetodo_pago(true);
 const closemetodo_pago = () => {
  setShowmetodo_pago(false);
  startTimer(); // Reinicia el temporizador
}*/

  const navigate = useNavigate();
  
  const [sintomas, setSintomas] = useState('');
  const [antecedentesFamiliares, setAntecedentesFamiliares] = useState('');
  const [antecedentesPersonales, setAntecedentesPersonales] = useState('');
  const [antecedentesQuirurgicos, setAntecedentesQuirurgicos] = useState('');
  const [alergias, setAlergias] = useState('');
  const [otrosDatos, setOtrosDatos] = useState('');
  //const [examenes, setExamenes] = useState(null); // Para archivos de exámenes
  const [montrarMensaje, setMostrarMensaje] = useState(false);
  const [message, setMessage] = useState('');

  // Estados para los checkboxes
  const [checkAntecedentesFamiliares, setCheckAntecedentesFamiliares] = useState(false);
  const [checkAntecedentesPersonales, setCheckAntecedentesPersonales] = useState(false);
  const [checkAntecedentesQuirurgicos, setCheckAntecedentesQuirurgicos] = useState(false);
  const [checkAlergias, setCheckAlergias] = useState(false);
  const [checkOtrosDatos, setCheckOtrosDatos] = useState(false);

  // Verificar sesión de usuario y pago
  useEffect(() => {
    const verificarSesion = async () => {
      try {
        const response = await axios.get('https://medico24x7.com/api/check_session_consultas.php', {
          withCredentials: true,
        }); // Ruta de tu archivo PHP

        // Depurar la respuesta
        //console.log('Respuesta de verificarSesion:', response.data);

        if (!response.data.usuario_id) {
          alert('Inicie sesión para acceder al área de consultas virtuales');
          navigate('/');
          return;
        }
        setIsAuthenticated(true);
      } catch (error) {
        console.error('Error verificando la sesión:', error);
      }
    };
    verificarSesion();
  }, [navigate]);

  // Estados para manejar los datos del formulario
 const [servicio, setServicio] = useState('');
 const [correo_doctor, setCorreo_Doctor] = useState('');
  const [doctores, setDoctores] = useState([]); // Estado para la lista de doctores
  const [doctor, setDoctor] = useState(""); // Estado para el doctor seleccionado

  // Función para manejar el cambio de servicio
  const handleServicioChange = async (e) => {
    const selectedServicio = e.target.value;
    setServicio(selectedServicio);

    // Hacer solicitud al backend para obtener los doctores que brindan el servicio seleccionado
    try {
      const response = await axios.get(`https://medico24x7.com/api/getDoctores.php?servicio=${selectedServicio}`, { withCredentials: true });
      setDoctores(response.data); // Actualizar la lista de doctores con la respuesta
      setDoctor(""); // Limpiar el doctor seleccionado
    } catch (error) {
      console.error("Error al obtener los doctores:", error);
    }
  };
  
  // Manejar el cambio de estado para checkboxes y campos
  const handleCheckboxChange = (setter, valueSetter, isChecked) => {
    setter(isChecked);
    if (isChecked) {
      valueSetter('No hay información');
    } else {
      valueSetter('');
    }
  };

  /*const handleFileChange = (event) => {
    setExamenes(event.target.files[0]);
  };*/

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const formData = new FormData();
      formData.append('correo_doctor', correo_doctor); 
      formData.append('servicio', servicio); 
      formData.append('sintomas', sintomas);
      formData.append('antecedentesFamiliares', antecedentesFamiliares);
      formData.append('antecedentesPersonales', antecedentesPersonales);
      formData.append('antecedentesQuirurgicos', antecedentesQuirurgicos);
      formData.append('alergias', alergias);
      formData.append('otrosDatos', otrosDatos);
      //formData.append('examenes', examenes); // Agregar archivo al formulario

      const response = await axios.post('https://medico24x7.com/api/enviar_consulta.php', formData, {
        withCredentials: true,
      });

      // Verificar la respuesta completa
      //console.log('Respuesta completa:', response);

      // Revisar si se recibe un objeto data y si success es true
      if (response.data && response.data.success) {
        //setMessage((response.data.message || ''));
        alert(response.data.message);
        setMostrarMensaje(false);
        //setFormVisible(false);
        //navigate('/pagos');
      } else {
        // Si no hay éxito, muestra el mensaje de error
        setMostrarMensaje(true);
        console.log('Error en la respuesta:', response.data);
        setMessage('Error al enviar el formulario: ' + (response.data?.message || 'Error desconocido'));
      }
    } catch (error) {
      // Captura y muestra el error
      setMostrarMensaje(true);
      console.error('Error al enviar el formulario:', error);
      setMessage('Error al enviar el formulario: ' + error.message);
    }
  };


 //Codigo para cerrear sesion despues de 30 minutos
const cerrarSesion = useCallback( async () => async () => {
  try {
    const response = await fetch('https://medico24x7.com/api/logout.php', {
      method: 'POST',
      credentials: 'include', // Incluir cookies
      headers: {
        'Content-Type': 'application/json',
      },
    });

    const data = await response.json();

    if (data.success) {
      alert('La sesión ha expirado. Serás redirigido al login.');
      navigate('/'); // Redirigir al login
      setIsAuthenticated(false);
    } else {
      alert('Error al cerrar la sesión');
    }
  } catch (error) {
    console.error('Error al cerrar la sesión:', error);
  }
}, [navigate]);

const timerRef = useRef(null);
  
// Define resetTimer con useCallback para evitar recrearlo en cada renderizado
const resetTimer = useCallback( async() => {
  clearTimeout(timerRef.current);
  timerRef.current = setTimeout(() => {
    cerrarSesion(); // Función para cerrar sesión (debes definirla o pasarla como dependencia si cambia)
  }, 1800000); // 30 minutos en milisegundos
}, [cerrarSesion]);

useEffect(() => {
  // Agregar eventos para detectar actividad del usuario
  window.addEventListener('mousemove', resetTimer);
  window.addEventListener('keypress', resetTimer);
  window.addEventListener('scroll', resetTimer);

  resetTimer(); // Establecer el temporizador al montar el componente

  // Limpiar eventos y temporizador al desmontar el componente
  return () => {
    clearTimeout(timerRef.current);
    window.removeEventListener('mousemove', resetTimer);
    window.removeEventListener('keypress', resetTimer);
    window.removeEventListener('scroll', resetTimer);
  };
}, [resetTimer]); // Incluir resetTimer como dependencia

/*Pagos*/
const [isAutorizacionPago, setIsAutorizacionPago] = useState(false);
const [isMensajeCunsulta, setIsMensajeCunsulta] = useState(false);
const [isMensajePago, setIsMensajePago] = useState('');
const [mostrarForm2, setMostrarForm2] = useState(false);
const [metodoPago, setMetodoPago] = useState('');
const [paypalClientId, setPaypalClientId] = useState('');
const [loadingPaypal, setLoadingPaypal] = useState(false);
const [formVisible, setFormVisible] = useState(true);
const [Value, setValue] = useState('');

const [checkboxVisible, setCheckboxVisible] = useState(true);



useEffect(() => {
  // Verificar si el usuario está autenticado al cargar la aplicación
  axios.get('https://medico24x7.com/api/check_session_pagos.php', { withCredentials: true })
    .then(response => {
      if (response.data.authenticated) {
        setValue(response.data.precio);
        //console.log(response.data.precio);
        //console.log(Value);
      } else {
        console.log('No autenticado');
        alert('Porfavor, inicie sesión');
      }
    })
    .catch(error => {
      console.error('Error al verificar la sesión:', error);
    });
}, [navigate]);


//Codigo para permitir pagos
useEffect(() => {
  const validar_autorizacion_pago = async () => {
  // Verificar si el usuario está autorizado para realizar el pago
  axios.get('https://medico24x7.com/api/check_autorizacion_pago.php', { withCredentials: true })
    .then(response => {
      if (response.data.authenticated) {
        if (["tipo_a", "tipo_b", "tipo_c"].includes(response.data.tipo)) {
          if (["tipo_a", "tipo_b"].includes(response.data.tipo)){
            setIsAutorizacionPago(true);
            setIsMensajeCunsulta("Ya Puede Realizar El Pago")
          } else{
            setIsAutorizacionPago(false);
            setIsMensajePago("No se puede pagar");
            setIsMensajeCunsulta("Su consulta es de tipo C, por lo cual no podemos atenderlo. Diríjase al hospital o clínica más cercana.")}
      } else {
          setIsAutorizacionPago(false);
          setIsMensajePago("No se le ha dado autorización");
          setIsMensajeCunsulta("Tipo de consulta");
      }
      } else {
        //console.log('No autorizado');
        setIsAutorizacionPago(false);
        setIsMensajePago("");
        setIsMensajeCunsulta("Se le asignara una clasificación luego de completar y enviar el formulario");
      }
    })
    .catch(error => {
      console.error('Error al verificar la sesión:', error);
    });
  }

  // Ejecutar la validación inicialmente
  validar_autorizacion_pago();

  // Configurar un intervalo para verificar el estado de la sesión cada cierto tiempo (por ejemplo, cada 5 segundos)
  const intervalIdPago = setInterval(() => {
    validar_autorizacion_pago();
  }, 5000); // 5000 milisegundos = 5 segundos

  // Limpiar el intervalo cuando el componente se desmonte
  return () => clearInterval(intervalIdPago);
}, []);


//Codigo para permitir consulta
useEffect(() => {
  const validar_autorizacion_consulta = async () => {
  // Verificar si el usuario está autorizado para realizar el pago
  axios.get('https://medico24x7.com/api/check_autorizacion_consulta.php', { withCredentials: true })
    .then(response => {
      if (response.data.authenticated) {
        navigate('/ConsultaChat');
      } 
    })
    .catch(error => {
      console.error('Error al verificar la sesión:', error);
    });
  }

  // Ejecutar la validación inicialmente
  validar_autorizacion_consulta();

  // Configurar un intervalo para verificar el estado de la sesión cada cierto tiempo (por ejemplo, cada 5 segundos)
  const intervalIdConsulta = setInterval(() => {
    validar_autorizacion_consulta();
  }, 5000); // 5000 milisegundos = 5 segundos

  // Limpiar el intervalo cuando el componente se desmonte
  return () => clearInterval(intervalIdConsulta);
}, [navigate]);


useEffect(() => {
  if (metodoPago === 'paypal' && paypalClientId) {
    const script = document.createElement('script');
    script.src = `https://www.paypal.com/sdk/js?client-id=${paypalClientId}&currency=USD`;
    script.async = true;
    script.onload = () => {
      window.paypal.Buttons({
        createOrder: async (data, actions) => {
          return actions.order.create({
            purchase_units: [{
              amount: {
                value: Value // Cambia por el valor real
              }
            }]
          });
        },
        onApprove: async (data, actions) => {
          const details = await actions.order.capture();
          alert('Pago completado por ' + details.payer.name.given_name);
          
          try {
            await actualizarSesion(); // Asegúrate de que esta función devuelva una promesa
            setIsAutorizacionPago(false);
            setFormVisible(false);
            setIsMensajeCunsulta("Su pago se ha enviado");
            setIsMensajePago("Por favor, espere hasta que se autorice la página de Chat...");
            //navigate('/consultas-virtuales');
            //window.location.reload(); // Ajusta la ruta según tu configuración
          } catch (error) {
            console.error('Error al actualizar la sesión:', error);
            alert('Ocurrió un error al procesar tu pago.');
          }
        },
        onError: (err) => {
          console.error('Error en el pago: ', err);
          alert('Hubo un error al procesar el pago, por favor intenta de nuevo.');
        }
      }).render('#paypal-container');
    };
    document.head.appendChild(script);
  }
}, [metodoPago, paypalClientId, Value]);

const actualizarSesion = () => {
  return axios.post('https://medico24x7.com/api/actualizar_sesion.php', { 
    metodo_de_pago: 'Paypal o Tarjeta' // Puedes enviar información adicional aquí si es necesario
  }, { withCredentials: true }) // Asegúrate de que las opciones están aquí
    .then(response => {
      if (response.data.status === 'success') {
        //console.log(response.data.message);
        alert('Todo se envió correctamente');
      } else {
        console.error(response.data.message);
        alert('Error al actualizar la sesión. Por favor, intenta de nuevo.');
      }
    })
    .catch(error => {
      console.error('Error al actualizar la sesión:', error);
      alert('Ocurrió un error al procesar tu pago.');
    });
};

const handleCheckboxChange2 = (e) => {
  const estado = e.target.checked ? 'si' : 'no';
  setMostrarForm2(e.target.checked);
  axios.post('https://medico24x7.com/api/procesar.php', { aceptar: estado }, { withCredentials: true })
    .then(response => {
      // Puedes manejar la respuesta si es necesario
      console.log(response.data);
      setCheckboxVisible(false);
    })
    .catch(error => {
      console.error('Error:', error);
    });
};

const handleMetodoPagoChange = (e) => {
  const metodo = e.target.value;
  setMetodoPago(metodo);
  //console.log(metodo);
  if (metodo === 'paypal') {
    // Obtener el client_id desde el servidor
    setLoadingPaypal(true);
    axios.get('https://medico24x7.com/api/obtener_paypal_config.php', { withCredentials: true })
      .then(response => {
        setPaypalClientId(response.data.client_id);
        setLoadingPaypal(false);
      })
      .catch(error => {
        console.error('Error al cargar los datos de PayPal:', error);
        alert('Hubo un problema al configurar PayPal, por favor intenta de nuevo.');
        setLoadingPaypal(false);
      });
  }
};

const handleTransferenciaSubmit = (e) => {
  e.preventDefault();
  const formData = new FormData(e.target);
  formData.append('metodo_de_pago', 'Transferencia');

  axios.post('https://medico24x7.com/api/actualizar_sesion.php', formData, { withCredentials: true })
    .then(response => {
      if (response.data.status === 'success') {
        //console.log(response.data.message);
        alert('Todo se envió correctamente');
        setIsAutorizacionPago(false);
        setFormVisible(false);
        setIsMensajeCunsulta("Su pago se ha enviado");
        setIsMensajePago("Por favor, espere hasta que se autorice la página de Chat...");
        //navigate('/consultas-virtuales');
        //window.location.reload(); // Ajusta la ruta según tu configuración
      } else {
        console.error(response.data.message);
        alert('Error al actualizar la sesión. Por favor, intenta de nuevo.');
      }
    })
    .catch(error => {
      console.error('Error al actualizar la sesión:', error);
      alert('Ocurrió un error al procesar tu pago.');
    });
};

const handleYappySubmit = (e) => {
  e.preventDefault();
  const formData = new FormData(e.target);
  formData.append('metodo_de_pago', 'Yappy');

  axios.post('https://medico24x7.com/api/actualizar_sesion.php', formData, { withCredentials: true })
    .then(response => {
      if (response.data.status === 'success') {
        //console.log(response.data.message);
        alert('Todo se envió correctamente');
        setIsAutorizacionPago(false);
        setFormVisible(false);
        setIsMensajeCunsulta("Su pago se ha enviado");
        setIsMensajePago("Por favor, espere hasta que se autorice la página de Chat...");
        //navigate('/consultas-virtuales');
        //window.location.reload(); // Ajusta la ruta según tu configuración
      } else {
        console.log(response.data.message);
        alert('Error al actualizar la sesión. Por favor, intenta de nuevo.'+ response.data.message);
      }
    })
    .catch(error => {
      console.error('Error al actualizar la sesión:', error);
      alert('Ocurrió un error al procesar tu pago.');
    });
};

if (!isAuthenticated) {
  return null;
}

  return (
    <div>
      <>
      <main>
        <div className="consulta-virtual">
          <form className='formVirtuales'>
            <fieldset>
              <h2>Información</h2>
              <fieldset>
              <div id='info-consultas-virtuales'>
              <p id='p-citas'>Esta es la página de "consultas virtuales". Si prefieres una atención presencial, puedes reservar una cita en la sección de "Consultas Presenciales".</p>
              </div>
              </fieldset>
              <p><strong>Precio de Consulta Virtuales:</strong></p>
<table border="1" cellSpacing="10" cellPadding="5" id="consultas_virtuales_table">
  <thead>
    <tr>
      <th>Disciplina</th>
      <th>Precio</th>
      <th>Tiempo</th>
      <th>Horario</th>
      <th>Días de atención médica</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>Medicina General</td>
      <td>$10</td>
      <td>1 hora</td>
      <td>8:00 AM - 10:00 PM</td>
      <td>Todos los días</td>
    </tr>
    <tr>
      <td>Psicología</td>
      <td>$40</td>
      <td>1 hora</td>
      <td>8:00 AM - 10:00 PM</td>
      <td>Todos los días</td>
    </tr>
    <tr>
      <td>Medicina Interna</td>
      <td>$40</td>
      <td>1 hora</td>
      <td>8:00 AM - 10:00 PM</td>
      <td>Todos los días</td>
    </tr>
  </tbody>
</table>
              {/*<p><strong>Horario:</strong> Lunes - Viernes de 4:30 p.m. - 9:00 p.m. / Sábado y Domingo de 9:00 a.m. - 5:00 p.m.</p>*/}
              <p><strong>Número de Celular:</strong> +507 6475-3624 (solo para mensajes de WhatsApp)</p>
              <p><strong>Correo:</strong> <a href="mailto:consultame@medico24x7.com">consultame@medico24x7.com</a></p>
              <p><strong>Ubicación:</strong> Panamá, Provincia: Coclé, Distrito: Penonomé, Quintas El Encanto, Casa 19A</p>
            </fieldset>
            <fieldset>
            <p>Clasificamos las consultas virtuales en tres tipos según su grado de importancia:</p>
        <ul>
          <li><strong>Tipo A</strong>: <u>Consultas de bajo riesgo</u>, ideales para revisiones de seguimiento, dudas generales o problemas de salud menores.</li>
          <li><strong>Tipo B</strong>: <u>Urgencias</u> que requieren atención médica oportuna, pero no representan un riesgo inmediato para la vida.</li>
          <li><strong>Tipo C</strong>: <u>Emergencias</u> que necesitan atención inmediata debido a un riesgo grave para la salud o la vida del paciente.</li>
        </ul>
        <p>Podemos atender consultas virtuales de tipo A y B. Sin embargo, si clasificamos la consulta como tipo C, no podremos atenderla virtualmente. En ese caso, le recomendamos al paciente acudir de inmediato al hospital, clínica o centro de salud más cercano.</p>
        <br />
        <p><strong>Clasificación:</strong> {isMensajeCunsulta}</p>
            </fieldset>
          </form>
        </div>
        
        {/* Formulario y demás */}

        {/*Formulario de pago*/}
        {formVisible && (
          !isAutorizacionPago ? (
          <div className="cont-formulario">
            <h1>Consultas Virtuales</h1>
          <form className='formVirtuales' id="Form" onSubmit={handleSubmit}>
            <fieldset>
              <legend>Formulario</legend>

              <div className="form-group">
        <label htmlFor="servicio">Tipo de Servicio:</label>
        <select id="servicio" name="servicio" value={servicio} onChange={handleServicioChange} required>
          <option value="" disabled>
            Seleccione el servicio
          </option>
          <option value="consulta_general">Medicina General</option>
          <option value="psicologia">Psicología</option>
          <option value="medicina_interna">Medicina Interna</option>
        </select>
      </div>

      {doctores.length > 0 ? (
        <div className="form-group">
          <label htmlFor="doctor">Seleccione el Doctor:</label>
          <select id="doctor" name="doctor" value={doctor} onChange={(e) => {setDoctor(e.target.value);
          
          // Busca el doctor seleccionado en la lista de doctores usando el correo
          const selectedDoctor = doctores.find((doc) => doc.correo === e.target.value);
          
          // Si el doctor es encontrado, actualiza el estado de correo_doctor
          if (selectedDoctor) {
            setCorreo_Doctor(selectedDoctor.correo);
          }}} required>
            <option value="" disabled>
              Seleccione el doctor
            </option>
            {doctores.map((doc) => (
              <option key={doc.id} value={doc.correo}>
                {doc.nombre}
              </option>
            ))}
          </select>
        </div>
      ) : (
        <p style={{ fontWeight: 'bold', color: 'red' }}>
          No hay doctores disponibles para esta especialidad.
        </p>

      )}

      <br />

              <span className="info-text">- Marca las <strong>casilla</strong> de las secciones que no vas a llenar.</span>

              <label htmlFor="sintomas">Síntomas:</label>
              <textarea id="sintomas" value={sintomas} onChange={(e) => setSintomas(e.target.value)} required></textarea>

              <label htmlFor="antecedentes_familiares">Antecedentes Familiares Patológicos:</label>
              <input
                type="checkbox"
                id="check_antecedentes_familiares"
                checked={checkAntecedentesFamiliares}
                onChange={(e) => handleCheckboxChange(setCheckAntecedentesFamiliares, setAntecedentesFamiliares, e.target.checked)}
              />
              {!checkAntecedentesFamiliares && !antecedentesFamiliares ? (
              <span style={{ color: 'red' }}><strong>Llene el campo o marque la casilla</strong></span>
              ) : null}
              <textarea
                id="antecedentes_familiares"
                value={antecedentesFamiliares}
                onChange={(e) => setAntecedentesFamiliares(e.target.value)}
                required={!checkAntecedentesFamiliares}
                disabled={checkAntecedentesFamiliares}
              ></textarea>

              <label htmlFor="antecedentes_personales">Antecedentes Personales Patológicos:</label>
              <input
                type="checkbox"
                id="check_antecedentes_personales"
                checked={checkAntecedentesPersonales}
                onChange={(e) => handleCheckboxChange(setCheckAntecedentesPersonales, setAntecedentesPersonales, e.target.checked)}
              />
              {!checkAntecedentesPersonales && !antecedentesPersonales ? (
              <span style={{ color: 'red' }}><strong>Llene el campo o marque la casilla</strong></span>
              ) : null}
              <textarea
                id="antecedentes_personales"
                value={antecedentesPersonales}
                onChange={(e) => setAntecedentesPersonales(e.target.value)}
                required={!checkAntecedentesPersonales}
                disabled={checkAntecedentesPersonales}
              ></textarea>

              <label htmlFor="antecedentes_quirurgicos">Antecedentes Quirúrgicos:</label>
              <input
                type="checkbox"
                id="check_antecedentes_quirurgicos"
                checked={checkAntecedentesQuirurgicos}
                onChange={(e) => handleCheckboxChange(setCheckAntecedentesQuirurgicos, setAntecedentesQuirurgicos, e.target.checked)}
              />
              {!checkAntecedentesQuirurgicos && !antecedentesQuirurgicos ? (
              <span style={{ color: 'red' }}><strong>Llene el campo o marque la casilla</strong></span>
              ) : null}
              <textarea
                id="antecedentes_quirurgicos"
                value={antecedentesQuirurgicos}
                onChange={(e) => setAntecedentesQuirurgicos(e.target.value)}
                required={!checkAntecedentesQuirurgicos}
                disabled={checkAntecedentesQuirurgicos}
              ></textarea>

              <label htmlFor="alergias">Alergias:</label>
              <input
                type="checkbox"
                id="check_alergias"
                checked={checkAlergias}
                onChange={(e) => handleCheckboxChange(setCheckAlergias, setAlergias, e.target.checked)}
              />
              {!checkAlergias && !alergias ? (
              <span style={{ color: 'red' }}><strong>Llene el campo o marque la casilla</strong></span>
              ) : null}
              <textarea
                id="alergias"
                value={alergias}
                onChange={(e) => setAlergias(e.target.value)}
                required={!checkAlergias}
                disabled={checkAlergias}
              ></textarea>

              <label htmlFor="otros_datos">Otros Datos Relevantes:</label>
              <input
                type="checkbox"
                id="check_otros_datos"
                checked={checkOtrosDatos}
                onChange={(e) => handleCheckboxChange(setCheckOtrosDatos, setOtrosDatos, e.target.checked)}
              />
              {!checkOtrosDatos && !otrosDatos ? (
              <span style={{ color: 'red' }}><strong>Llene el campo o marque la casilla</strong></span>
              ) : null}
              <textarea
                id="otros_datos"
                value={otrosDatos}
                onChange={(e) => setOtrosDatos(e.target.value)}
                required={!checkOtrosDatos}
                disabled={checkOtrosDatos}
              ></textarea>
            </fieldset>

            {/*<fieldset>
              <legend>Objetivo</legend>
              <label htmlFor="examenes" className="files">Sube tus exámenes de laboratorio (PDF o imágenes):</label>
              <input type="file" id="examenes" name="examenes" multiple onChange={handleFileChange} />
            </fieldset>*/}
            {doctores.length > 0 && (
            <button type="submit" id="submitButton">Enviar Consulta</button>
            )}

            {montrarMensaje && (
            <div id="message">{message}</div>
            )}
          </form>
          </div>
    ) : (
        <div className="cont-formulario">
          <h1>Área de Pagos</h1>
          <form id="Form">
            <fieldset>
              <legend>Acuerdo Entre Ambas Partes</legend>
              <p>Acuerdo...</p>
              <label htmlFor="acuerdo">
              {checkboxVisible && (
                <input type="checkbox" id="acuerdo" name="acuerdo" onChange={handleCheckboxChange2} />
              )}
                 Estoy de acuerdo con los términos y condiciones
              </label>
            </fieldset>
          </form>

          {mostrarForm2 && (
            <form id="Form2" className="seleccionar">
              <fieldset>
                <legend>Selecciona un método de pago</legend>
                <select id="metodo-pago" value={metodoPago} onChange={handleMetodoPagoChange}>
                  <option value="">Seleccione...</option>
                  <option value="paypal">PayPal y Tarjetas Débito/Crédito</option>
                  <option value="transferencia">Transferencia Bancaria (Solo Panamá)</option>
                  <option value="yappy">Yappy (Solo Panamá)</option>
                </select>
              </fieldset>
            </form>
          )}

          {/* Formulario para PayPal */}
          {metodoPago === 'paypal' && (
            <div id="paypal-container" className="formulario">
              {loadingPaypal && <p>Cargando PayPal...</p>}
            </div>
          )}

          {/* Formulario para Transferencia Bancaria */}
          {metodoPago === 'transferencia' && (
            <form id="form-transferencia" className="formulario" onSubmit={handleTransferenciaSubmit}>
              <h3>Pago con Transferencia Bancaria</h3>
              <p>Por favor realiza la transferencia a una de estas cuentas:</p>
              <br />
              <p>Banco General: Enrique Puentes</p>
              <p>Cuenta Ahorros: 04-37-98-032556-7</p>
              <br />
              <p>Scotia Bank (Bank of Nova Scotia): Enrique Puentes Ruiz.</p>
              <p>Cuenta Ahorros: 200000122373</p>
              <br />
              <p>Por favor, no escriba nada en descripción o comentarios.</p>
              <fieldset>
                <label htmlFor="comprobante_tranferencia" className="files"><strong>Sube foto o captura del comprobante:</strong></label>
                <input type="file" id="comprobante_tranferencia" name="comprobante_tranferencia" multiple required />
              </fieldset>
              <button type="submit">Confirmar Transferencia</button>
            </form>
          )}

          {/* Formulario para Yappy */}
          {metodoPago === 'yappy' && (
            <form id="form-yappy" className="formulario" onSubmit={handleYappySubmit}>
              <h3>Pago con Yappy</h3>
              <p>Por favor realiza la transferencia al siguiente número de celular:</p>
              <br />
              <p>Número de celular: 6475-3624 (Enrique Puentes).</p>
              <br />
              <p>Por favor, no escriba nada en descripción o comentarios.</p>
              <fieldset>
                <label htmlFor="comprobante_tranferencia" className="files"><strong>Sube foto o captura del comprobante:</strong></label>
                <input type="file" id="comprobante_tranferencia" name="comprobante_tranferencia" multiple required/>
              </fieldset>
              <button type="submit">Confirmar Pago</button>
            </form>
          )}
        </div>
      
    ))}
    {!formVisible && (
        <div className="cont-formulario">
          <p>{isMensajePago}</p>
        </div>
          )}

      </main>

      </>
    </div>
  );
};

export default ConsultasVirtuales;
