import React, { useEffect, useRef, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import '../../css/Admin/Admin.css'; // Tu CSS

const AdminWelcome = () => {

  const navigate = useNavigate();

//Codigo para cerrar sesión
const cerrarSesion = useCallback( async () => async () => {
  try {
    const response = await fetch('https://medico24x7.com/api/AdminDoctores/logout_admin_doctor.php', {
      method: 'POST',
      credentials: 'include', // Incluir cookies
      headers: {
        'Content-Type': 'application/json',
      },
    });

    const data = await response.json();

    if (data.success) {
      alert('La sesión ha expirado. Serás redirigido al login.');
      navigate('/AdminDoctor'); // Redirigir al login
    } else {
      alert('Error al cerrar la sesión');
    }
  } catch (error) {
    console.error('Error al cerrar la sesión:', error);
  }
}, [navigate]);
  
  const timerRef = useRef(null);
  
    // Define resetTimer con useCallback para evitar recrearlo en cada renderizado
    const resetTimer = useCallback( async() => {
      clearTimeout(timerRef.current);
      timerRef.current = setTimeout(() => {
        cerrarSesion(); // Función para cerrar sesión (debes definirla o pasarla como dependencia si cambia)
      }, 1800000); // 30 minutos en milisegundos
    }, [cerrarSesion]);
  
    useEffect(() => {
      // Agregar eventos para detectar actividad del usuario
      window.addEventListener('mousemove', resetTimer);
      window.addEventListener('keypress', resetTimer);
      window.addEventListener('scroll', resetTimer);
  
      resetTimer(); // Establecer el temporizador al montar el componente
  
      // Limpiar eventos y temporizador al desmontar el componente
      return () => {
        clearTimeout(timerRef.current);
        window.removeEventListener('mousemove', resetTimer);
        window.removeEventListener('keypress', resetTimer);
        window.removeEventListener('scroll', resetTimer);
      };
    }, [resetTimer]); // Incluir resetTimer como dependencia

  return (
    <div className='body_Admin'>
    <main className="admin-welcome">
      <div className="welcome-container">
        <h1>Bienvenido</h1>
        <p>Estamos encantados de tenerte de vuelta.</p>
      </div>
    </main>
    </div>
  );
};

export default AdminWelcome;