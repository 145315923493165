import { useEffect } from 'react';

function useMenuToggle(mobileMenuId, navListId) {
  useEffect(() => {
    const mobileMenu = document.getElementById(mobileMenuId);
    const navList = document.getElementById(navListId);

    //console.log("Mobile Menu ID:", mobileMenu);
    //console.log("Nav List ID:", navList);

    const handleClick = () => {
      if (navList) {
        navList.classList.toggle('active');
      }
    };

    if (mobileMenu) {
      //console.log("Event listener added");
      mobileMenu.addEventListener('click', handleClick);
    }

    return () => {
      if (mobileMenu) {
        //console.log("Event listener removed");
        mobileMenu.removeEventListener('click', handleClick);
      }
    };
  }, [mobileMenuId, navListId]);
}

export default useMenuToggle;