import React, { useEffect, useRef, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import '../css/MedicinaGeneral.css'; //

export default function NavBar() {

  const navigate = useNavigate();

  //Codigo para cerrear sesion despues de 30 minutos
const cerrarSesion = useCallback( async () => async () => {
  try {
    const response = await fetch('https://medico24x7.com/api/logout.php', {
      method: 'POST',
      credentials: 'include', // Incluir cookies
      headers: {
        'Content-Type': 'application/json',
      },
    });

    const data = await response.json();

    if (data.success) {
      alert('La sesión ha expirado. Serás redirigido al login.');
      navigate('/'); // Redirigir al login
    } else {
      alert('Error al cerrar la sesión');
    }
  } catch (error) {
    console.error('Error al cerrar la sesión:', error);
  }
}, [navigate]);

const timerRef = useRef(null);
  
// Define resetTimer con useCallback para evitar recrearlo en cada renderizado
const resetTimer = useCallback( async() => {
  clearTimeout(timerRef.current);
  timerRef.current = setTimeout(() => {
    cerrarSesion(); // Función para cerrar sesión (debes definirla o pasarla como dependencia si cambia)
  }, 1800000); // 30 minutos en milisegundos
}, [cerrarSesion]);

useEffect(() => {
  // Agregar eventos para detectar actividad del usuario
  window.addEventListener('mousemove', resetTimer);
  window.addEventListener('keypress', resetTimer);
  window.addEventListener('scroll', resetTimer);

  resetTimer(); // Establecer el temporizador al montar el componente

  // Limpiar eventos y temporizador al desmontar el componente
  return () => {
    clearTimeout(timerRef.current);
    window.removeEventListener('mousemove', resetTimer);
    window.removeEventListener('keypress', resetTimer);
    window.removeEventListener('scroll', resetTimer);
  };
}, [resetTimer]); // Incluir resetTimer como dependencia

  
  return (
    <div className='body_medicina_general'>
  
    <main className="medicina_general">

<div id="header_medicina_general">
  <h1 className="title">Medicina General</h1>
  <p className="intro-text">Información sobre los servicios de medicina general que ofrece la clínica.</p>
</div>

<div className="flex-container-general">
  <section className="services">
    <h2 className="section-title">Nuestros Servicios</h2>
    <ul className="service-list">
      <li className="service-item">Consultas generales</li>
      <li className="service-item">Chequeos de rutina</li>
      <li className="service-item">Diagnóstico y tratamiento de enfermedades comunes</li>
      <li className="service-item">Referencias a especialistas</li>
      <li className="service-item">Vacunación</li>
    </ul>
  </section>

  <section className="why-us">
    <h2 className="section-title">¿Por qué elegirnos?</h2>
    <p className="info-text">Nuestros médicos están altamente capacitados para brindar un cuidado integral y personalizado. Nos comprometemos a:</p>
    <ul className="benefits-list">
      <li className="benefit-item">Ofrecer atención de calidad y centrada en el paciente</li>
      <li className="benefit-item">Proporcionar diagnósticos precisos</li>
      <li className="benefit-item">Mantener un ambiente amigable y seguro</li>
    </ul>
  </section>

  <section className="contact">
    <h2 className="section-title">¡Atiéndete con nosotros!</h2>
    {/* <p className="info-text">Para más información o para agendar una cita, seleccione una de las siguientes opciones:</p> */}
    <div className="button-container">
      <Link to="/citas" className="contact-button">Consulta Presencial</Link>
      <Link to="/consultas-virtuales" className="contact-button">Consulta Virtual</Link>
    </div>
  </section>
</div>
    </main>
  </div>
  );
}
