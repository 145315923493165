import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import '../../css/Admin/Sugerencias_Admin_Nuevo.css';

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    // Verificar si el usuario está autenticado al cargar la aplicación
    axios.get('https://medico24x7.com/api/Admin/check_session_admin_otros.php', { withCredentials: true })
      .then(response => {
        if (response.data.authenticated) {
          setIsAuthenticated(true);
        } else {
          console.log('No autenticado');
          alert('Inicie sesión para acceder al área de consultas virtuales');
          navigate('/Admin');// Ajusta la ruta según tu configuración
        }
      })
      .catch(error => {
        console.error('Error al verificar la sesión:', error);
      });
  }, [navigate]);


//Conexión a Base de datos
const [sugerencia, setSugerencia] = useState([]);

  useEffect(() => {
    // Realizar la solicitud al backend para obtener los datos del usuario
    axios.get('https://medico24x7.com/api/Admin/Nuevo_PHP/Sugerencias_Admin.php', { withCredentials: true }) // Cambia la ruta a tu API
      .then(response => {
        //console.log(response.data);
        setSugerencia(response.data); // Supongo que devuelve un array de usuarios
      })
      .catch(error => {
        console.error('Error al obtener los datos:', error);
      });
  }, []);



 //Codigo para cerrar sesión
 const cerrarSesion = useCallback( async () => {
  try {
  const response = await fetch('https://medico24x7.com/api/Admin/logout_admin.php', {
  method: 'POST',
  credentials: 'include', // Incluir cookies
  headers: {
    'Content-Type': 'application/json',
  },
  });
  
  const data = await response.json();
  
  if (data.success) {
  alert('La sesión ha expirado.');
  navigate('/Admin'); // Redirigir al login
  } else {
  alert('Error al cerrar la sesión');
  }
  } catch (error) {
  console.error('Error al cerrar la sesión:', error);
  }
  }, [navigate]);
  
  const timerRef = useRef(null);
  
    // Define resetTimer con useCallback para evitar recrearlo en cada renderizado
    const resetTimer = useCallback( async() => {
      clearTimeout(timerRef.current);
      timerRef.current = setTimeout(() => {
        cerrarSesion(); // Función para cerrar sesión (debes definirla o pasarla como dependencia si cambia)
      }, 1800000); // 30 minutos en milisegundos
    }, [cerrarSesion]);
  
    useEffect(() => {
      // Agregar eventos para detectar actividad del usuario
      window.addEventListener('mousemove', resetTimer);
      window.addEventListener('keypress', resetTimer);
      window.addEventListener('scroll', resetTimer);
  
      resetTimer(); // Establecer el temporizador al montar el componente
  
      // Limpiar eventos y temporizador al desmontar el componente
      return () => {
        clearTimeout(timerRef.current);
        window.removeEventListener('mousemove', resetTimer);
        window.removeEventListener('keypress', resetTimer);
        window.removeEventListener('scroll', resetTimer);
      };
    }, [resetTimer]); // Incluir resetTimer como dependencia

  if (!isAuthenticated) {
    return null; // O un loader
  }

  return (
    <div className='sugerencia_admin'>
    <main className='sugerencia_admin_main'>
        <div class="parent-sugerencia">
        <div class="div1-sugerencia">
        <ul className="ul-sugerencia-cont">
        {sugerencia.map((product) => (
          <section key={product.id} className="li-sugerencia-cont">
            <div class="parent-sugerencia-cont">
                <div class="div1-sugerencia-cont">
                    <p className="p-element-sugerencia"><strong>Nombre:</strong> {product.nombre} {product.apellido}</p>
                </div>
                <div class="div2-sugerencia-cont">
                    <p className="p-element-sugerencia"><strong>Correo:</strong> {product.correo}</p>
                </div>
                <div class="div3-sugerencia-cont">
                    <p className="p-element-sugerencia"><strong>Sugerencia:</strong> {product.sugerencia}</p>
                </div>
                <div class="div4-sugerencia-cont">
                    <p className="p-element-sugerencia"><strong>Fecha de registro:</strong> {product.fecha_registro}</p>
                </div>
            </div>

          </section>
        ))}
      </ul>
      </div>
      </div>
      </main>
    </div>
  );
}

export default App;