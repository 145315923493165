import React from 'react';
import { useLocation } from 'react-router-dom';
import 'react-datepicker/dist/react-datepicker.css';
import '../css/RedesSociales.css';

function App() {
    const location = useLocation(); // Usa useLocation para obtener la ruta actual
return (
    <>
 {/* Barra de redes sociales */}
{["/admin", "/admin/", "/Admin/", "/Admin", "/Consultas_Admin", "/Citas_Admin", "/Recibo_Admin", "/Sugerencias_Admin", "/Login_Admin", "/Registro_Admin"].includes(location.pathname) ? (
        <>
      </>
      ) : (
        ["/Consultas", "/Citas_usuario", "/Recibo_pagos"].includes(location.pathname) ? (
          <>
          </>
        ) : (
          <>
           <div className="social-bar">
 <ul>
   <li>
     <a href="https://web.facebook.com/medico24x7" target="_blank" className="icon facebook" rel="noopener noreferrer">
       <i className="fab fa-facebook-f"></i>
     </a>
   </li>
   <li>
     <a href="https://www.instagram.com/md24x7/" target="_blank" className="icon instagram" rel="noopener noreferrer">
       <i className="fab fa-instagram"></i>
     </a>
   </li>
 </ul>
</div>
          </>
        )
        )}
</>
);
}

export default App;