import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import '../../../css/AdminDoctores/Formularios/Cert_Salud.css';

function MedicalCertificateForm() {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [emailError, setEmailError] = useState(false);
    const navigate = useNavigate();
  
    useEffect(() => {
      // Verificar si el usuario está autenticado al cargar la aplicación
      axios.get('https://medico24x7.com/api/AdminDoctores/check_session_admin_doctor_otros.php', { withCredentials: true })
        .then(response => {
          if (response.data.authenticated) {
            setIsAuthenticated(true);
          } else {
            console.log('No autenticado');
            alert('Inicie sesión para acceder al área de consultas virtuales');
            navigate('/AdminDoctor');// Ajusta la ruta según tu configuración
          }
        })
        .catch(error => {
          console.error('Error al verificar la sesión:', error);
        });
    }, [navigate]);


    // Estado inicial del formulario
    const initialFormData = {
        correoPaciente: '',
        ssn: '',
        date: '',
        patientName: '',
        dias_incapacidad: '',
        //del dia
        hora_inicio:'',
        dia_inicio: '',
        mes_inicio: '',
        ano_inicio: '',
        //hasta
        hora_final:'',
        dia_final: '',
        mes_final: '',
        ano_final: '',

        doctorName: '',
        doctorSignature: '',
        regNumber: ''
    };

     // Validar el correo electrónico
  const validateEmail = (e) => {
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    setEmailError(!emailPattern.test(e.target.value));
  };

    const [formData, setFormData] = useState(initialFormData);

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };


    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
          const response = await axios.post(
            'https://medico24x7.com/api/AdminDoctores/Formularios/Cert_Incapacidad.php',
            formData,
            {
                responseType: 'blob', // para recibir el PDF como Blob
                withCredentials: true
            }
        );
        
        // Verificar y extraer el nombre del archivo del encabezado 'Content-Disposition'
        const contentDisposition = response.headers['content-disposition'];
        let fileName = 'archivo.pdf'; // Nombre por defecto si no se encuentra
        
        if (contentDisposition) {
            // Buscar el nombre de archivo en el encabezado
            const match = contentDisposition.match(/filename="(.+)"/);
            if (match && match[1]) {
                fileName = match[1]; // Asigna el nombre del archivo si se encuentra
            }
        }
        
        // Descargar el archivo
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        link.remove();

            // Limpiar el formulario después de enviar
            setFormData(initialFormData);
        } catch (error) {
            console.error("Error generating PDF:", error);
            alert("Error generating PDF.");
        }
    };


    const [consultas, setConsultas] = useState([]);

    // Cargar las consultas desde la base de datos cuando se monta el componente
    useEffect(() => {
      axios.get('https://medico24x7.com/api/AdminDoctores/Formularios/Info_Documentos/DB_Cert_Incapacidad.php', { withCredentials: true })
        .then(response => {
          setConsultas(response.data);
        })
        .catch(error => {
          console.error('Error al obtener las consultas:', error);
        });
    }, []);
  
    // Descargar archivo
    const handleDownload = async (id) => {
      // Llama al script PHP directamente para forzar la descarga
      const url = `https://medico24x7.com/api/AdminDoctores/Formularios/Info_Documentos/Descargar_Cert_Incapacidad.php?id=${id}`;
      // Crea un enlace temporal y simula un clic
      const a = document.createElement('a');
      a.href = url;
      a.setAttribute('download', ''); // Esto asegura que el navegador entienda que es una descarga
      document.body.appendChild(a);
      a.click(); // Simula el clic en el enlace
      document.body.removeChild(a); // Elimina el enlace
  };


 //Codigo para cerrar sesión
 const cerrarSesion = useCallback( async () => async () => {
  try {
    const response = await fetch('https://medico24x7.com/api/AdminDoctores/logout_admin_doctor.php', {
      method: 'POST',
      credentials: 'include', // Incluir cookies
      headers: {
        'Content-Type': 'application/json',
      },
    });

    const data = await response.json();

    if (data.success) {
      alert('La sesión ha expirado. Serás redirigido al login.');
      navigate('/AdminDoctor'); // Redirigir al login
    } else {
      alert('Error al cerrar la sesión');
    }
  } catch (error) {
    console.error('Error al cerrar la sesión:', error);
  }
}, [navigate]);
  
  const timerRef = useRef(null);
  
    // Define resetTimer con useCallback para evitar recrearlo en cada renderizado
    const resetTimer = useCallback( async() => {
      clearTimeout(timerRef.current);
      timerRef.current = setTimeout(() => {
        cerrarSesion(); // Función para cerrar sesión (debes definirla o pasarla como dependencia si cambia)
      }, 1800000); // 30 minutos en milisegundos
    }, [cerrarSesion]);
  
    useEffect(() => {
      // Agregar eventos para detectar actividad del usuario
      window.addEventListener('mousemove', resetTimer);
      window.addEventListener('keypress', resetTimer);
      window.addEventListener('scroll', resetTimer);
  
      resetTimer(); // Establecer el temporizador al montar el componente
  
      // Limpiar eventos y temporizador al desmontar el componente
      return () => {
        clearTimeout(timerRef.current);
        window.removeEventListener('mousemove', resetTimer);
        window.removeEventListener('keypress', resetTimer);
        window.removeEventListener('scroll', resetTimer);
      };
    }, [resetTimer]); // Incluir resetTimer como dependencia

  if (!isAuthenticated) {
    return null; // O un loader
  }

    return (
      <div className='Documentos'>
        <form className="form_salud" onSubmit={handleSubmit}>
          <h1>Formulario</h1>
            <label>Correo del paciente:
            <input
                type="email"
                name="correoPaciente"
                value={formData.correoPaciente}
                onChange={(e) => { handleChange(e); validateEmail(e); }}
                required
              />
              {emailError && <p className='p_error'>Por favor, ingresa un correo válido.</p>}
            </label>
            <label>N° S.S.:
                <input type="text" name="ssn" value={formData.ssn} onChange={handleChange} />
            </label>
            <label>Fecha:
                <input type="date" name="date" value={formData.date} onChange={handleChange} />
            </label>
            <label>Nombre del Paciente:
                <input type="text" name="patientName" value={formData.patientName} onChange={handleChange} />
            </label>
            <label>Dias de incapacidad:
                <input type="number" name="dias_incapacidad" value={formData.dias_incapacidad} onChange={handleChange} />
            </label>
            <label>Hora de inicio:
                <input type="time" name="hora_inicio" value={formData.hora_inicio} onChange={handleChange} />
            </label>
            <label>Día de inicio:
                <input type="text" name="dia_inicio" value={formData.dia_inicio} onChange={handleChange} />
            </label>
            <label>Mes de inicio:
                <input type="text" name="mes_inicio" value={formData.mes_inicio} onChange={handleChange} />
            </label>
            <label>Año de inicio:
                <input type="text" name="ano_inicio" value={formData.ano_inicio} onChange={handleChange} />
            </label>

            <label>Hora de final:
                <input type="time" name="hora_final" value={formData.hora_final} onChange={handleChange} />
            </label>
            <label>Día de final:
                <input type="text" name="dia_final" value={formData.dia_final} onChange={handleChange} />
            </label>
            <label>Mes de final:
                <input type="text" name="mes_final" value={formData.mes_final} onChange={handleChange} />
            </label>
            <label>Año de final:
                <input type="text" name="ano_final" value={formData.ano_final} onChange={handleChange} />
            </label>

            <label>Nombre del Médico:
                <input type="text" name="doctorName" value={formData.doctorName} onChange={handleChange} />
            </label>
            <label>Firma del Médico:
                <input type="text" name="doctorSignature" value={formData.doctorSignature} onChange={handleChange} />
            </label>
            <label>N° de Reg.:
                <input type="text" name="regNumber" value={formData.regNumber} onChange={handleChange} />
            </label>
            <button type="submit">Generar PDF</button>
        </form>


        <div className='Info_Documentos'>
        <table className='consultas_table_doctor'>
        <thead>
          <tr>
            <th>Nombre del Archivo</th>
            <th>Fecha de creación</th>
            <th>Archivo Exámenes</th>
          </tr>
        </thead>
        <tbody>
          {
          consultas.length > 0 ? (
          consultas.map((consulta) => (
            <tr key={consulta.id}>
              <td>{consulta.nombre_pdf}</td>
              <td>{consulta.created_at}</td>
              <td>
              {consulta.pdf_data ? (
              <button onClick={() => handleDownload(consulta.id)}>
              Descargar
              </button>
              ) : (
                'No disponible'
                 )}
              </td>
            </tr>
            ))) : (
            <tr>
              <td colSpan="8">No hay datos disponibles</td>
            </tr>
            )}
        </tbody>
      </table>
        </div>
        </div>
    );
}

export default MedicalCertificateForm;