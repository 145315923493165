import React, { useEffect, useState } from "react";
import axios from "axios";
import "../../css/Admin/FloatingConsulta.css";

const FloatingConsulta = ({ token, onClose }) => {
  const [consultas, setConsultas] = useState([]); // Inicializar como un arreglo vacío para evitar errores
  const [loading, setLoading] = useState(true); // Estado de carga

  useEffect(() => {
    const fetchData = async () => {
      if (token) {
        try {
          const response = await axios.get(
            "https://medico24x7.com/api/AdminDoctores/Nuevo_PHP/Consulta_Admin_Doctores_Boton.php",
            {
              params: { token }, // Pasar el token como query parameter
              withCredentials: true, // Si necesitas cookies
            }
          );
          setConsultas(response.data); // Actualiza los datos
          setLoading(false); // Desactiva el estado de carga
        } catch (error) {
          console.error("Error al obtener datos:", error);
          setLoading(false); // Asegúrate de desactivar el estado de carga incluso en errores
        }
      }
    };

    fetchData();
  }, [token]);

  // Función para actualizar una consulta
  const actualizarConsulta = (consultaId) => {
    const analisis = document.getElementById(`analisis_${consultaId}`).value;
    const plan = document.getElementById(`plan_${consultaId}`).value;
    const fecha = document.getElementById(`fecha_${consultaId}`).value;
    const hora = document.getElementById(`hora_${consultaId}`).value;
    const revisado = document.getElementById(`revisado_${consultaId}`).checked ? 1 : 0;

    const consulta = consultas.find(c => c.id === consultaId);
    const correo = consulta.correo;

    const formData = new FormData();
    formData.append('consulta_id', consultaId);
    formData.append('correo', correo);
    formData.append('analisis', analisis);
    formData.append('plan', plan);
    formData.append('fecha', fecha);
    formData.append('hora', hora);
    formData.append('revisado', revisado);

    axios.post('https://medico24x7.com/api/AdminDoctores/actualizar_consulta_doctor.php', formData, { withCredentials: true })
      .then(response => {
        if (response.data.success) {
          alert('Consulta actualizada con éxito');
        } else {
          alert('Error al actualizar la consulta: ' + response.data.message);
          console.log(response.data);
        }
      })
      .catch(error => {
        console.error('Error al actualizar la consulta:', error);
        alert('Ocurrió un error al actualizar la consulta.');
      });
  };

  const handleDownload = async (id) => {
    // Llama al script PHP directamente para forzar la descarga
    const url = `https://medico24x7.com/api/AdminDoctores/descargar_archivo_consulta.php?id=${id}`;
    // Crea un enlace temporal y simula un clic
    const a = document.createElement('a');
    a.href = url;
    a.setAttribute('download', ''); // Esto asegura que el navegador entienda que es una descarga
    document.body.appendChild(a);
    a.click(); // Simula el clic en el enlace
    document.body.removeChild(a); // Elimina el enlace
  };

  return (
    <div className="floating-overlay">
      <div className="floating-botton">
        <button onClick={onClose} className="floating-close-button">
          Cerrar
        </button>
      </div>
      <div className="floating-modal">
        <table className="consultas_table">
          {loading ? (
            //animación de carga de datos
            <p>Cargando datos...</p>
          ) : consultas.length > 0 ? (
            consultas.map((consulta, index) => (
              <tbody key={consulta.id}>
                <tr>
                  <td><strong>Nombre</strong></td>
                  <td>{consulta.nombre}</td>
                </tr>
                <tr>
                  <td><strong>Apellido</strong></td>
                  <td>{consulta.apellido}</td>
                </tr>
                <tr>
                  <td><strong>Nacionalidad</strong></td>
                  <td>{consulta.nacionalidad}</td>
                </tr>
                <tr>
                  <td><strong>Cédula</strong></td>
                  <td>{consulta.cedula}</td>
                </tr>
                <tr>
                  <td><strong>Género</strong></td>
                  <td>{consulta.genero}</td>
                </tr>
                <tr>
                  <td><strong>Edad</strong></td>
                  <td>{consulta.edad}</td>
                </tr>
                <tr>
                  <td><strong>Dirección</strong></td>
                  <td>{consulta.direccion}</td>
                </tr>
                <tr>
                  <td><strong>Síntomas</strong></td>
                  <td>{consulta.sintomas}</td>
                </tr>
                <tr>
                  <td><strong>Antecedentes Familiares</strong></td>
                  <td>{consulta.antecedentes_familiares}</td>
                </tr>
                <tr>
                  <td><strong>Antecedentes Personales</strong></td>
                  <td>{consulta.antecedentes_personales}</td>
                </tr>
                <tr>
                  <td><strong>Antecedentes Quirúrgicos</strong></td>
                  <td>{consulta.antecedentes_quirurgicos}</td>
                </tr>
                <tr>
                  <td><strong>Alergias</strong></td>
                  <td>{consulta.alergias}</td>
                </tr>
                <tr>
                  <td><strong>Peso</strong></td>
                  <td>{consulta.peso}</td>
                </tr>
                <tr>
                  <td><strong>Talla</strong></td>
                  <td>{consulta.talla}</td>
                </tr>
                <tr>
                  <td><strong>Presión Arterial</strong></td>
                  <td>{consulta.presion_arterial}</td>
                </tr>
                <tr>
                  <td><strong>Frecuencia Cardiaca</strong></td>
                  <td>{consulta.frecuencia_cardiaca}</td>
                </tr>
                <tr>
                  <td><strong>Frecuencia Respiratoria</strong></td>
                  <td>{consulta.frecuencia_respiratoria}</td>
                </tr>
                <tr>
                  <td><strong>Otros Datos</strong></td>
                  <td>{consulta.otros_datos}</td>
                </tr>
                <tr>
                  <td><strong>Correo</strong></td>
                  <td id={`correo_${consulta.id}`} defaultValue={consulta.correo}>{consulta.correo}</td>
                </tr>
                <tr>
                  <td><strong>Celular</strong></td>
                  <td>{consulta.celular}</td>
                </tr>
                <tr>
                  <td><strong>Analisis</strong></td>
                  <td>
                    <textarea id={`analisis_${consulta.id}`} defaultValue={consulta.analisis}></textarea>
                  </td>
                </tr>
                <tr>
                  <td><strong>Plan</strong></td>
                  <td>
                    <textarea id={`plan_${consulta.id}`} defaultValue={consulta.plan}></textarea>
                  </td>
                </tr>
                <tr>
                  <td><strong>Fecha de la Próxima Consulta</strong></td>
                  <td>
                    <input type="date" id={`fecha_${consulta.id}`} defaultValue={consulta.fecha} />
                  </td>
                </tr>
                <tr>
                  <td><strong>Hora de la Próxima Consulta</strong></td>
                  <td>
                    <input type="time" id={`hora_${consulta.id}`} defaultValue={consulta.hora} />
                  </td>
                </tr>
                <tr>
                  <td><strong>Fecha de Registro</strong></td>
                  <td>{consulta.fecha_registro}</td>
                </tr>
                <tr>
                  <td><strong>Archivo Exámenes</strong></td>
                  <td>
                    {consulta.archivo_examenes_encrypted ? (
                      <button onClick={() => handleDownload(consulta.id)}>
                        Descargar
                      </button>
                    ) : (
                      'No disponible'
                    )}
                  </td>
                </tr>

                <tr>
                  <td><strong>Revisado</strong></td>
                  <td>
                    <input type="checkbox" id={`revisado_${consulta.id}`} defaultChecked={consulta.revisado} />
                  </td>
                </tr>

                <tr>
                  <td><strong>Actualizar Consulta</strong></td>
                  <td>
                    <button type="button" onClick={() => actualizarConsulta(consulta.id)}>Actualizar</button>
                  </td>
                </tr>

              </tbody>
            ))
          ) : (
            <p>No se encontraron datos.</p>
          )}
        </table>
      </div>
    </div>

  );
};

export default FloatingConsulta;
