import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import '../../css/Usuarios/Usuarios.css';

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    // Verificar si el usuario está autenticado al cargar la aplicación
    axios.get('https://medico24x7.com/api/check_session_pagos.php', { withCredentials: true })
      .then(response => {
        if (response.data.authenticated) {
          setIsAuthenticated(true);
        } else {
          console.log('No autenticado');
          alert('Inicie sesión para acceder al área de consultas virtuales');
          navigate('/');// Ajusta la ruta según tu configuración
        }
      })
      .catch(error => {
        console.error('Error al verificar la sesión:', error);
      });
  }, [navigate]);


//Conexión a Base de datos
const [userInfo, setUserInfo] = useState({});
const [loading, setLoading] = useState(true);
const [error, setError] = useState(null);

useEffect(() => {
    // Realizamos una petición al servidor PHP usando axios
    axios.get('https://medico24x7.com/api/Usuarios/Usuarios.php', { withCredentials: true }) // Cambia esto por la ruta real de tu archivo PHP
      .then((response) => {
        const data = response.data;
        if (data.error) {
          setError(data.error);
        } else {
          setUserInfo(data);
        }
        setLoading(false);
      })
      .catch((err) => {
        setError('Error al obtener los datos del usuario');
        setLoading(false);
      });
  }, []);



//Codigo para cerrear sesion despues de 30 minutos
const cerrarSesion = useCallback( async () => async () => {
  try {
    const response = await fetch('https://medico24x7.com/api/logout.php', {
      method: 'POST',
      credentials: 'include', // Incluir cookies
      headers: {
        'Content-Type': 'application/json',
      },
    });

    const data = await response.json();

    if (data.success) {
      alert('La sesión ha expirado. Serás redirigido al login.');
      navigate('/'); // Redirigir al login
      setIsAuthenticated(false);
    } else {
      alert('Error al cerrar la sesión');
    }
  } catch (error) {
    console.error('Error al cerrar la sesión:', error);
  }
}, [navigate]);

const timerRef = useRef(null);
  
// Define resetTimer con useCallback para evitar recrearlo en cada renderizado
const resetTimer = useCallback( async() => {
  clearTimeout(timerRef.current);
  timerRef.current = setTimeout(() => {
    cerrarSesion(); // Función para cerrar sesión (debes definirla o pasarla como dependencia si cambia)
  }, 1800000); // 30 minutos en milisegundos
}, [cerrarSesion]);

useEffect(() => {
  // Agregar eventos para detectar actividad del usuario
  window.addEventListener('mousemove', resetTimer);
  window.addEventListener('keypress', resetTimer);
  window.addEventListener('scroll', resetTimer);

  resetTimer(); // Establecer el temporizador al montar el componente

  // Limpiar eventos y temporizador al desmontar el componente
  return () => {
    clearTimeout(timerRef.current);
    window.removeEventListener('mousemove', resetTimer);
    window.removeEventListener('keypress', resetTimer);
    window.removeEventListener('scroll', resetTimer);
  };
}, [resetTimer]); // Incluir resetTimer como dependencia

  if (!isAuthenticated) {
    return null; // O un loader
  }

  return (
    <div>

      <main id='usuarios'>
      <div className="info_p">
      {loading ? (
        <p>Cargando...</p>
      ) : error ? (
        <p>{error}</p>
      ) : (
        <>
                <h2>Información de Usuario</h2>
                <form id="userForm_usuario">
                    <p>
                        <label htmlFor="nombre">Nombre:</label>
                        <input type="text" id="nombre" name="nombre" value={userInfo.nombre || ''} readOnly />
                    </p>
                    <p>
                        <label htmlFor="apellido">Apellido:</label>
                        <input type="text" id="apellido" name="apellido" value={userInfo.apellido || ''} readOnly />
                    </p>
                    <p>
                        <label htmlFor="edad">Edad:</label>
                        <input type="text" id="edad" name="edad" value={userInfo.edad || ''} readOnly />
                    </p>
                    <p>
                        <label htmlFor="genero">Género:</label>
                        <input type="text" id="genero" name="genero" value={userInfo.genero || ''} readOnly />
                    </p>
                    <p>
                        <label htmlFor="nacionalidad">Nacionalidad:</label>
                        <input type="text" id="nacionalidad" name="nacionalidad" value={userInfo.nacionalidad || ''} readOnly />
                    </p>
                    <p>
                        <label htmlFor="cedula">Cedula:</label>
                        <input type="text" id="cedula" name="cedula" value={userInfo.cedula || ''} readOnly />
                    </p>
                    <p>
                        <label htmlFor="direccion">Dirección:</label>
                        <input type="text" id="direccion" name="direccion" value={userInfo.direccion || ''} readOnly />
                    </p>
                    <p>
                        <label htmlFor="celular">Celular:</label>
                        <input type="tel" id="celular" name="celular" value={userInfo.celular || ''} readOnly />
                    </p>
                    <p>
                        <label htmlFor="correo">Correo:</label>
                        <input type="email" id="correo" name="correo" value={userInfo.correo || ''} readOnly />
                    </p>
                </form>
                </>
                )}
            </div>
      </main>
    </div>
  );
}

export default App;