import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import '../../../css/AdminDoctores/Formularios/Cert_Salud.css';

function Solicitud_Examenes() {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [emailError, setEmailError] = useState(false);
    const [phoneError, setPhoneError] = useState(false);
    const navigate = useNavigate();
  
    useEffect(() => {
      // Verificar si el usuario está autenticado al cargar la aplicación
      axios.get('https://medico24x7.com/api/AdminDoctores/check_session_admin_doctor_otros.php', { withCredentials: true })
        .then(response => {
          if (response.data.authenticated) {
            setIsAuthenticated(true);
          } else {
            console.log('No autenticado');
            alert('Inicie sesión para acceder al área de consultas virtuales');
            navigate('/AdminDoctor');// Ajusta la ruta según tu configuración
          }
        })
        .catch(error => {
          console.error('Error al verificar la sesión:', error);
        });
    }, [navigate]);


    // Estado inicial del formulario
    const initialFormData = {
        correoPaciente: '',
        patientName: '',
        Celular: '',
        CIP: '',
        Edad: '',
        Sexo: 'Hombre',
        doctorSignature: '',
    };

    const [formData, setFormData] = useState(initialFormData);

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };


    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post(
                'https://medico24x7.com/api/AdminDoctores/Formularios/Solicitud_Examenes.php', 
                formData, 
                {
                    responseType: 'blob', // inicialmente, para recibir el PDF
                    withCredentials: true
                }
            );
    
            // Verificar y extraer el nombre del archivo del encabezado 'Content-Disposition'
        const contentDisposition = response.headers['content-disposition'];
        let fileName = 'archivo.pdf'; // Nombre por defecto si no se encuentra
        
        if (contentDisposition) {
            // Buscar el nombre de archivo en el encabezado
            const match = contentDisposition.match(/filename="(.+)"/);
            if (match && match[1]) {
                fileName = match[1]; // Asigna el nombre del archivo si se encuentra
            }
        }
        
        // Descargar el archivo
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        link.remove();

            // Limpiar el formulario después de enviar
            //setFormData(initialFormData);
        } catch (error) {
            console.error("Error generating PDF:", error);
            alert("Error generating PDF.");
        }
    };

    const [consultas, setConsultas] = useState([]);

  // Cargar las consultas desde la base de datos cuando se monta el componente
  useEffect(() => {
    axios.get('https://medico24x7.com/api/AdminDoctores/Formularios/Info_Documentos/DB_Solicitud_Examenes.php', { withCredentials: true })
      .then(response => {
        setConsultas(response.data);
      })
      .catch(error => {
        console.error('Error al obtener las consultas:', error);
      });
  }, []);

  // Descargar archivo
  const handleDownload = async (id) => {
    // Llama al script PHP directamente para forzar la descarga
    const url = `https://medico24x7.com/api/AdminDoctores/Formularios/Info_Documentos/Descargar_Solicitud_Examenes.php?id=${id}`;
    // Crea un enlace temporal y simula un clic
    const a = document.createElement('a');
    a.href = url;
    a.setAttribute('download', ''); // Esto asegura que el navegador entienda que es una descarga
    document.body.appendChild(a);
    a.click(); // Simula el clic en el enlace
    document.body.removeChild(a); // Elimina el enlace
};

 //Codigo para cerrar sesión
 const cerrarSesion = useCallback( async () => async () => {
  try {
    const response = await fetch('https://medico24x7.com/api/AdminDoctores/logout_admin_doctor.php', {
      method: 'POST',
      credentials: 'include', // Incluir cookies
      headers: {
        'Content-Type': 'application/json',
      },
    });

    const data = await response.json();

    if (data.success) {
      alert('La sesión ha expirado. Serás redirigido al login.');
      navigate('/AdminDoctor'); // Redirigir al login
    } else {
      alert('Error al cerrar la sesión');
    }
  } catch (error) {
    console.error('Error al cerrar la sesión:', error);
  }
}, [navigate]);
  
  const timerRef = useRef(null);
  
    // Define resetTimer con useCallback para evitar recrearlo en cada renderizado
    const resetTimer = useCallback( async() => {
      clearTimeout(timerRef.current);
      timerRef.current = setTimeout(() => {
        cerrarSesion(); // Función para cerrar sesión (debes definirla o pasarla como dependencia si cambia)
      }, 1800000); // 30 minutos en milisegundos
    }, [cerrarSesion]);
  
    useEffect(() => {
      // Agregar eventos para detectar actividad del usuario
      window.addEventListener('mousemove', resetTimer);
      window.addEventListener('keypress', resetTimer);
      window.addEventListener('scroll', resetTimer);
  
      resetTimer(); // Establecer el temporizador al montar el componente
  
      // Limpiar eventos y temporizador al desmontar el componente
      return () => {
        clearTimeout(timerRef.current);
        window.removeEventListener('mousemove', resetTimer);
        window.removeEventListener('keypress', resetTimer);
        window.removeEventListener('scroll', resetTimer);
      };
    }, [resetTimer]); // Incluir resetTimer como dependencia

    // Validar el correo electrónico
  const validateEmail = (e) => {
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    setEmailError(!emailPattern.test(e.target.value));
  };

  // useEffect para validar el número de celular cuando cambian country_code o celular
  useEffect(() => {
    const { country_code, Celular } = formData;
    if (country_code && Celular) {
      const phonePatterns = {
        "+54": /^[0-9]{10}$/,
        "+591": /^[0-9]{8}$/,
        "+55": /^[0-9]{11}$/,
        "+56": /^[0-9]{9}$/,
        "+57": /^[0-9]{10}$/,
        "+506": /^[0-9]{8}$/,
        "+53": /^[0-9]{8}$/,
        "+593": /^[0-9]{9}$/,
        "+503": /^[0-9]{8}$/,
        "+502": /^[0-9]{8}$/,
        "+504": /^[0-9]{8}$/,
        "+52": /^[0-9]{10}$/,
        "+505": /^[0-9]{8}$/,
        "+507": /^[0-9]{8}$/,  // Panamá
        "+595": /^[0-9]{9}$/,
        "+51": /^[0-9]{9}$/,
        "+1-787": /^[0-9]{10}$/,
        "+1-809": /^[0-9]{10}$/,
        "+598": /^[0-9]{9}$/,
        "+58": /^[0-9]{10}$/,
      };

      // Limpiar el número de celular eliminando todos los caracteres que no sean dígitos
      const cleanedPhone = Celular.replace(/\D/g, '');
      const pattern = phonePatterns[country_code];

      // Agregar logs para depuración
      console.log(`Validando celular: Código de país: "${country_code}", Celular: "${cleanedPhone}", Patrón: ${pattern}`);

      if (pattern) {
        setPhoneError(!pattern.test(cleanedPhone));
      } else {
        setPhoneError(true); // No hay patrón definido para el country_code seleccionado
      }
    } else {
      setPhoneError(false);
    }
  }, [formData]);

  if (!isAuthenticated) {
    return null; // O un loader
  }

    return (
      <div className='Documentos'>
        <form className="form_salud" onSubmit={handleSubmit}>
            <label>Correo del paciente:
            <input
                type="email"
                name="correoPaciente"
                value={formData.correoPaciente}
                onChange={(e) => { handleChange(e); validateEmail(e); }}
                required
              />
              {emailError && <p className='p_error'>Por favor, ingresa un correo válido.</p>}
            </label>
            <label>Nombre del Paciente:
                <input type="text" name="patientName" value={formData.patientName} onChange={handleChange} />
            </label>
            <label htmlFor="country_code">Código de País:
            <select
                id="country_code"
                name="country_code"
                value={formData.country_code}
                onChange={handleChange}
                required
              >
                <option value="+54">Argentina (+54)</option>
                <option value="+591">Bolivia (+591)</option>
                <option value="+55">Brasil (+55)</option>
                <option value="+56">Chile (+56)</option>
                <option value="+57">Colombia (+57)</option>
                <option value="+506">Costa Rica (+506)</option>
                <option value="+53">Cuba (+53)</option>
                <option value="+593">Ecuador (+593)</option>
                <option value="+503">El Salvador (+503)</option>
                <option value="+502">Guatemala (+502)</option>
                <option value="+504">Honduras (+504)</option>
                <option value="+52">México (+52)</option>
                <option value="+505">Nicaragua (+505)</option>
                <option value="+507">Panamá (+507)</option>
                <option value="+595">Paraguay (+595)</option>
                <option value="+51">Perú (+51)</option>
                <option value="+1-787">Puerto Rico (+1-787)</option>
                <option value="+1-809">República Dominicana (+1-809)</option>
                <option value="+598">Uruguay (+598)</option>
                <option value="+58">Venezuela (+58)</option>
                {/* Añadir más opciones de país aquí */}
              </select>
            </label>
            <label htmlFor="Celular">Celular:
            <input
                type="tel"
                name="Celular"
                value={formData.Celular}
                onChange={handleChange}
                pattern="\d{8}"
                title="Por favor, ingresa exactamente 8 dígitos."
                required
              />
              {phoneError && <p className='p_error'>El número no es válido.</p>}
            </label>
            <label>CIP:
                <input type="text" name="CIP" value={formData.CIP} onChange={handleChange} />
            </label>
            <label>Edad:
                <input type="text" name="Edad" value={formData.Edad} onChange={handleChange} />
            </label>
            <label>Género:
                <select
                name="Sexo"
                value={formData.Sexo}
                onChange={handleChange}
                required
                >
                <option value="Hombre">Hombre</option>
                <option value="Mujer">Mujer</option>
                </select>
            </label>
            <label>Firma del Médico:
                <input type="text" name="doctorSignature" value={formData.doctorSignature} onChange={handleChange} />
            </label>
            <button type="submit">Generar PDF</button>
        </form>

        <div className='Info_Documentos'>
        <table className='consultas_table_doctor'>
        <thead>
          <tr>
            <th>Nombre del Archivo</th>
            <th>Fecha de creación</th>
            <th>Archivo Exámenes</th>
          </tr>
        </thead>
        <tbody>
          {
          consultas.length > 0 ? (
          consultas.map((consulta) => (
            <tr key={consulta.id}>
              <td>{consulta.nombre_pdf}</td>
              <td>{consulta.created_at}</td>
              <td>
              {consulta.pdf_data ? (
              <button onClick={() => handleDownload(consulta.id)}>
              Descargar
              </button>
              ) : (
                'No disponible'
                 )}
              </td>
            </tr>
            ))) : (
            <tr>
              <td colSpan="8">No hay datos disponibles</td>
            </tr>
            )}
        </tbody>
      </table>
        </div>
        </div>
    );
}

export default Solicitud_Examenes;