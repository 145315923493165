import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import '../../css/AdminDoctores/Fecha_Admin_Doctores.css';

function FechaNoDisponible() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    // Verificar si el usuario está autenticado al cargar la aplicación
    axios.get('https://medico24x7.com/api/AdminDoctores/check_session_admin_doctor_otros.php', { withCredentials: true })
      .then(response => {
        if (response.data.authenticated) {
          setIsAuthenticated(true);
        } else {
          console.log('No autenticado');
          alert('Inicie sesión para acceder al área de consultas virtuales');
          navigate('/AdminDoctor');// Ajusta la ruta según tu configuración
        }
      })
      .catch(error => {
        console.error('Error al verificar la sesión:', error);
      });
  }, [navigate]);


  const [fecha, setFecha] = useState('');
  const [mensaje, setMensaje] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post('https://medico24x7.com/api/AdminDoctores/ingresar_fechas.php', {
        date: fecha,
      }, { withCredentials: true });

      setMensaje(response.data.message);
      setFecha('');
    } catch (error) {
      setMensaje('Error al enviar la fecha');
      console.error(error);
    }
  };

 //Codigo para cerrar sesión
const cerrarSesion = useCallback( async () => async () => {
  try {
    const response = await fetch('https://medico24x7.com/api/AdminDoctores/logout_admin_doctor.php', {
      method: 'POST',
      credentials: 'include', // Incluir cookies
      headers: {
        'Content-Type': 'application/json',
      },
    });

    const data = await response.json();

    if (data.success) {
      alert('La sesión ha expirado. Serás redirigido al login.');
      navigate('/AdminDoctor'); // Redirigir al login
    } else {
      alert('Error al cerrar la sesión');
    }
  } catch (error) {
    console.error('Error al cerrar la sesión:', error);
  }
}, [navigate]);
  
  const timerRef = useRef(null);
  
    // Define resetTimer con useCallback para evitar recrearlo en cada renderizado
    const resetTimer = useCallback( async() => {
      clearTimeout(timerRef.current);
      timerRef.current = setTimeout(() => {
        cerrarSesion(); // Función para cerrar sesión (debes definirla o pasarla como dependencia si cambia)
      }, 1800000); // 30 minutos en milisegundos
    }, [cerrarSesion]);
  
    useEffect(() => {
      // Agregar eventos para detectar actividad del usuario
      window.addEventListener('mousemove', resetTimer);
      window.addEventListener('keypress', resetTimer);
      window.addEventListener('scroll', resetTimer);
  
      resetTimer(); // Establecer el temporizador al montar el componente
  
      // Limpiar eventos y temporizador al desmontar el componente
      return () => {
        clearTimeout(timerRef.current);
        window.removeEventListener('mousemove', resetTimer);
        window.removeEventListener('keypress', resetTimer);
        window.removeEventListener('scroll', resetTimer);
      };
    }, [resetTimer]); // Incluir resetTimer como dependencia

  if (!isAuthenticated) {
    return null; // O un loader
  }

  return (
    <div className='FacheAdminDoctor'>
    <div className="fecha-no-disponible-container">
  <h2 className="fecha-no-disponible-title">Ingresar Fecha No Disponible</h2>
  <form onSubmit={handleSubmit} className="fecha-no-disponible-form">
    <label className="fecha-no-disponible-label">
      Fecha:
      <input
        type="date"
        value={fecha}
        onChange={(e) => setFecha(e.target.value)}
        required
        className="fecha-no-disponible-input"
      />
    </label>
    <button type="submit" className="fecha-no-disponible-button">Enviar</button>
  </form>
  {mensaje && <p className="fecha-no-disponible-message">{mensaje}</p>}
</div>
</div>
  );
}

export default FechaNoDisponible;